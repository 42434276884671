import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import { ContextApiContext } from "../../context/ContextApi";
import { SendRequest } from "../../common/Common";
import { Constant } from "../../common/Constants";
import { Alert } from "react-bootstrap";

export default function LoginModal(props) {
  const navigateToPath = (path) => {
    navigate(path);
  };
  const { contextState, updateContextState } = useContext(ContextApiContext);
  const [showLoginModal, setShowLoginModalArea] = useState(
    contextState.show_login_modal
  );
  const [showSendOtp, setShowSendOtp] = useState(true);
  const [showValidateOtp, setshowValidateOtp] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [user, setUser] = useState({});

  const navigate = useNavigate();
  // console.log("props create otpnew modal ", props);

  const atemptLogin = async () => {
    console.log("get otp");
    try {
      if ( !email) {
        return;
      }
      let formData = new FormData();
      // formData.append("phone_no", phone_no);
      formData.append("email", email);
      formData.append("password", password);
      const res = await SendRequest(
        "post",
        Constant.login,
        formData
      );
      console.log("get otp res ", res);

      if (res.status) {
        res.response.is_loggedin = true;
        updateContextState(res.response, "update_user");
        updateContextState(false, "show_login_modal");
        navigateToPath("/");

              } else {
        if (res.error && res.error.message) {
          updateContextState(res.error.message[0], "error_msg");
        } else {
          updateContextState("Somthing went wrong contact admin", "error_msg");
        }
      }
      
    } catch (error) {
      updateContextState(
        "Login failed. Please check your credentials.",
        "error_msg"
      );
      console.error("Error during login:", error);
    }
  };
  
  const validateOtp = async () => {
    if (!otp) {
      return;
    }
    console.log("user", user);
    let formData = new FormData();
    formData.append("otp", otp);
    formData.append("access_token", user.access_token);
    const res = await SendRequest("post", Constant.atempt_login, formData);

    if (res.status) {
      setShowSendOtp(true);
      setshowValidateOtp(false);
      res.response.is_loggedin = true;
      updateContextState(res.response, "update_user");
      updateContextState(false, "show_login_modal");
    } else {
      if (res.error && res.error.message) {
        updateContextState(res.error.message[0], "error_msg");
      } else {
        updateContextState("Somthing went wrong contact admin.", "error_msg");
      }
    }
  };

  const handleCloseOtp = () => {
    setShowSendOtp(true);
    setshowValidateOtp(false);
    setShowLoginModalArea(false);
    updateContextState(false, "show_login_modal");
    // console.log("handle close otpfalse ", props.handleOtpPaymentModals);
  };

  return (
    <>
      <div className="modal_plac">
        <Modal
          // backdrop="static"
          // show={showLoginModal}
          show={contextState.show_login_modal}
          onHide={handleCloseOtp}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Login</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!showSendOtp ? null : (
              <>
                
                <Form.Label htmlFor="basic-url">Email</Form.Label>
                <InputGroup className="mb-3">
                  
                  <Form.Control
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    placeholder="name@example.com"
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
                <Form.Label htmlFor="basic-url">Password</Form.Label>
                <InputGroup className="mb-3">
                 
                  <Form.Control
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    placeholder="Enter Password"
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputGroup>
                <Button onClick={() => atemptLogin()}>Submit</Button>
              </>
            )}

            {!showValidateOtp ? null : (
              <>
                <Form.Label htmlFor="basic-url">Enter OTP</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    placeholder="OTP"
                    onChange={(e) => {
                      setOtp(e.target.value);
                    }}
                  />
                </InputGroup>
                <Button onClick={() => validateOtp()}>Login</Button>
              </>
            )}
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
