import React, { useEffect, useContext } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./../../styles/Services_payment.css";
import "./../../styles/categorydetaillisting.css";
import Carousel from "react-bootstrap/Carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Alert from "react-bootstrap/Alert";

import {
  faAnglesLeft,
  faUser,
  faSuitcaseRolling,
  faDoorOpen,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import InputGroup from "react-bootstrap/InputGroup";
// import Nav_bar_area from "./NavBar";
import { useNavigate, useLocation } from "react-router-dom";
// import { Constant } from "../common/Constants";
// import { ContextApiContext } from "../context/ContextApi";
// import Common, {
//   googleTranslate,
//   SendRequest,
//   SendRequestContetType,
// } from "../common/Common";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";

export default function Pagination(props) {

  const navigate = useNavigate();

  const navigateToPath = (path, props) => {
    navigate(path, { state: props });
  };

  
  return (
    <div>
      <Container>
        <Row>
          <div className="">
            {props.currentPage > 1 ?
            <Button
              variant="primary"
              className="last-b"
              onClick={() => {
                props.getCategoryResultList((props.currentPage-1) );
              }}
            >
              <FontAwesomeIcon icon={faAnglesLeft} />
            </Button>:null }
            <Button variant="secondary" className="bfor">
              Page {props.currentPage} of {props.lastPage}
            </Button>{" "}
            {props.currentPage <props.lastPage ?
            <Button
              variant="primary"
              className="last-b"
              onClick={() => {
                props.getCategoryResultList((parseInt(props.currentPage)+1));
              }}
            >
              <FontAwesomeIcon icon={faAnglesRight} />
            </Button>:null }
          </div>
        </Row>
      </Container>
    </div>
  );
}
