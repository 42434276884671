import React, { useEffect, useContext } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./../styles/Services_payment.css";
import Carousel from "react-bootstrap/Carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Alert from "react-bootstrap/Alert";

import {
  faSliders,
  faUser,
  faSuitcaseRolling,
  faDoorOpen,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import InputGroup from "react-bootstrap/InputGroup";
import Nav_bar_area from "./NavBar";
import { useNavigate, useLocation } from "react-router-dom";
import { Constant } from "../common/Constants";
import { ContextApiContext } from "../context/ContextApi";
import Common, {
  googleTranslate,
  SendRequest,
  SendRequestContetType,
} from "../common/Common";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";

export default function ListCars() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div>
      <Nav_bar_area className="colooo" />
      <div className="dggg">
        <Row className="md-4">
          <Col xs={12} md={12}>
            <div className="hvbj">
              <h1>Searching For Child Care</h1>
              <h3>Located near Toronto</h3>
              <h5>Distance 300km, Sort Results By: Distance</h5>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="box" onClick={() => handleTabClick(2)}>
              <Row>
                <Col xs={12} md={6}>
                  <div className="inner-box1">
                    <h2>MERN Stack Developer</h2>
                    <h6> ZEMS Marketing</h6>
                    <h6>Hyderabad</h6>
                    <h6>
                      You must have proficiency in MongoDB, Express.js,
                      React.js, and Node.js, along with strong skills in
                      JavaScript, HTML, and CSS.
                    </h6>
                    <Dropdown as={ButtonGroup} className="fsfsd">
                      <Button variant="" className="bt">
                        Show More
                      </Button>

                      <Dropdown.Toggle
                        split
                        variant="success"
                        id="dri dropdown-split-basic"
                      />

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">
                          Another action
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-3">
                          Something else
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="inner-box2">
                    <h2>Rs 50,000 - Rs 70,000 a month</h2>
                    <h4>Full-time</h4>
                    <h5>
                      <img src="/images/slicing/download.png" /> Easy Apply
                    </h5>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col>
            <div className="box" onClick={() => handleTabClick(2)}>
              <Row>
                <Col xs={12} md={6}>
                  <div className="inner-box1">
                    <h2>Full Stack Developer(Laravel & Vuejs) Remote</h2>
                    <h6> ZEMS Marketing</h6>
                    <h6>Hyderabad</h6>
                    <h6>
                      You must have proficiency in MongoDB, Express.js,
                      React.js, and Node.js, along with strong skills in
                      JavaScript, HTML, and CSS.
                    </h6>
                    <Dropdown as={ButtonGroup} className="fsfsd">
                      <Button variant="" className="bt">
                        Show More
                      </Button>

                      <Dropdown.Toggle
                        split
                        variant="success"
                        id="dri dropdown-split-basic"
                      />

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">
                          Another action
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-3">
                          Something else
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="inner-box2">
                    <h2>Rs 55,000 - Rs 75,000 a month</h2>
                    <h4>Full-time</h4>
                    <h5>
                      <img src="/images/slicing/download.png" /> Easy Apply
                    </h5>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="box" onClick={() => handleTabClick(2)}>
              <Row>
                <Col xs={12} md={6}>
                  <div className="inner-box1">
                    <h2>Frontend Developer (MERN Stack)- Office Based</h2>
                    <h6> ZEMS Marketing</h6>
                    <h6>Hyderabad</h6>
                    <h6>
                      You must have proficiency in MongoDB, Express.js,
                      React.js, and Node.js, along with strong skills in
                      JavaScript, HTML, and CSS.
                    </h6>
                    <Dropdown as={ButtonGroup} className="fsfsd">
                      <Button variant="" className="bt">
                        Show More
                      </Button>

                      <Dropdown.Toggle
                        split
                        variant="success"
                        id="dri dropdown-split-basic"
                      />

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">
                          Another action
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-3">
                          Something else
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="inner-box2">
                    <h2>Rs 50,000 - Rs 70,000 a month</h2>
                    <h4>Full-time</h4>
                    <h5>
                      <img src="/images/slicing/download.png" /> Easy Apply
                    </h5>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col>
            <div className="box" onClick={() => handleTabClick(2)}>
              <Row>
                <Col xs={12} md={6}>
                  <div className="inner-box1">
                    <h2>Technical Lead (Full - Stack)Remote</h2>
                    <h6> ZEMS Marketing</h6>
                    <h6>Hyderabad</h6>
                    <h6>
                      You must have proficiency in MongoDB, Express.js,
                      React.js, and Node.js, along with strong skills in
                      JavaScript, HTML, and CSS.
                    </h6>
                    <Dropdown as={ButtonGroup} className="fsfsd">
                      <Button variant="" className="bt">
                        Show More
                      </Button>

                      <Dropdown.Toggle
                        split
                        variant="success"
                        id="dri dropdown-split-basic"
                      />

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">
                          Another action
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-3">
                          Something else
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="inner-box2">
                    <h2>Rs 60,000 - Rs 70,000 a month</h2>
                    <h4>Full-time</h4>
                    <h5>
                      <img src="/images/slicing/download.png" /> Easy Apply
                    </h5>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="box" onClick={() => handleTabClick(2)}>
              <Row>
                <Col xs={12} md={6}>
                  <div className="inner-box1">
                    <h2>React/Nextjs/MERN StackDeveloper</h2>
                    <h6> ZEMS Marketing</h6>
                    <h6>Hyderabad</h6>
                    <h6>
                      You must have proficiency in MongoDB, Express.js,
                      React.js, and Node.js, along with strong skills in
                      JavaScript, HTML, and CSS.
                    </h6>
                    <Dropdown as={ButtonGroup} className="fsfsd">
                      <Button variant="" className="bt">
                        Show More
                      </Button>

                      <Dropdown.Toggle
                        split
                        variant="success"
                        id="dri dropdown-split-basic"
                      />

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">
                          Another action
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-3">
                          Something else
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="inner-box2">
                    <h2>Rs 50,000 - Rs 70,000 a month</h2>
                    <h4>Full-time</h4>
                    <h5>
                      <img src="/images/slicing/download.png" /> Easy Apply
                    </h5>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col>
            <div className="box" onClick={() => handleTabClick(2)}>
              <Row>
                <Col xs={12} md={6}>
                  <div className="inner-box1">
                    <h2>MERN Stack Developer</h2>
                    <h6> ZEMS Marketing</h6>
                    <h6>Hyderabad</h6>
                    <h6>
                      You must have proficiency in MongoDB, Express.js,
                      React.js, and Node.js, along with strong skills in
                      JavaScript, HTML, and CSS.
                    </h6>
                    <Dropdown as={ButtonGroup} className="fsfsd">
                      <Button variant="" className="bt">
                        Show More
                      </Button>

                      <Dropdown.Toggle
                        split
                        variant="success"
                        id="dri dropdown-split-basic"
                      />

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">
                          Another action
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-3">
                          Something else
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="inner-box2">
                    <h2>Rs 50,000 - Rs 70,000 a month</h2>
                    <h4>Full-time</h4>
                    <h5>
                      <img src="/images/slicing/download.png" /> Easy Apply
                    </h5>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="box" onClick={() => handleTabClick(2)}>
              <Row>
                <Col xs={12} md={6}>
                  <div className="inner-box1">
                    <h2>Crypto Full Stack Developer(Hyderabad- Remote)</h2>
                    <h6> ZEMS Marketing</h6>
                    <h6>Hyderabad</h6>
                    <h6>
                      You must have proficiency in MongoDB, Express.js,
                      React.js, and Node.js, along with strong skills in
                      JavaScript, HTML, and CSS.
                    </h6>
                    <Dropdown as={ButtonGroup} className="fsfsd">
                      <Button variant="" className="bt">
                        Show More
                      </Button>

                      <Dropdown.Toggle
                        split
                        variant="success"
                        id="dri dropdown-split-basic"
                      />

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">
                          Another action
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-3">
                          Something else
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="inner-box2">
                    <h2>Rs 50,000 - Rs 70,000 a month</h2>
                    <h4>Full-time</h4>
                    <h5>
                      <img src="/images/slicing/download.png" /> Easy Apply
                    </h5>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col>
            <div className="box" onClick={() => handleTabClick(2)}>
              <Row>
                <Col xs={12} md={6}>
                  <div className="inner-box1">
                    <h2>Python Developer - Remote</h2>
                    <h6> ZEMS Marketing</h6>
                    <h6>Hyderabad</h6>
                    <h6>
                      You must have proficiency in MongoDB, Express.js,
                      React.js, and Node.js, along with strong skills in
                      JavaScript, HTML, and CSS.
                    </h6>
                    <Dropdown as={ButtonGroup} className="fsfsd">
                      <Button variant="" className="bt">
                        Show More
                      </Button>

                      <Dropdown.Toggle
                        split
                        variant="success"
                        id="dri dropdown-split-basic"
                      />

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">
                          Another action
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-3">
                          Something else
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="inner-box2">
                    <h2>Rs 50,000 - Rs 70,000 a month</h2>
                    <h4>Full-time</h4>
                    <h5>
                      <img src="/images/slicing/download.png" /> Easy Apply
                    </h5>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="">
            <Button variant="secondary" className="bfor">
              Page 1 of 6
            </Button>{" "}
            <Button variant="primary" className="last-b">
              1
            </Button>{" "}
            <Button variant="primary" className="last-b">
              2
            </Button>{" "}
            <Button variant="primary" className="last-b">
              <FontAwesomeIcon icon={faAnglesRight} />
            </Button>{" "}
          </div>
        </Row>
      </div>
      <Container fluid className="bg_dblu">
        <Container>
          <Row>
            <Col xs={12} md={3}>
              <img
                className="logo_ft"
                src="/images/slicing/Help101-Web.png"
                alt="Content"
              />
            </Col>
            <Col xs={12} md={9}>
              <Row>
                <Col xs={12} md={12} className="ser_area">
                  <InputGroup className="mb-3 ft_mb3">
                    <Form.Control
                      placeholder="Your E-mail"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      className="ft_inp"
                    />
                    <InputGroup.Text
                      id="basic-addon2"
                      className="ft_inp_btn addon2ft"
                    >
                      Subscribe Now!
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
              </Row>
              <Row className="m-3">
                <Col xs={12} md={4}>
                  <ul className="list_ft">
                    <h5>Partnership</h5>
                    <li>Websites</li>
                    <li>Social Media</li>
                    <li>Branding</li>
                  </ul>
                </Col>
                <Col xs={12} md={4}>
                  <ul className="list_ft">
                    <h5>About</h5>
                    <li>Our Projects</li>
                    <li>Careers</li>
                  </ul>
                </Col>
                <Col xs={12} md={4}>
                  <ul className="list_ft">
                    <h5>Support</h5>
                    <li>Support Request</li>
                    <li>Contact</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Row>
          <Col>
            <div className="socialicon">
              <img src="/images/slicing/instagram-logo.png" />
              <img src="/images/slicing/3536424.png" />
              <img src="/images/slicing/124013.png" />
              <img src="/images/slicing/3536394.png" />
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <div className="ft-hr">
            <h4>Copyright © 2023-2028 Help101.ca. All Rights Reserved.</h4>
          </div>
        </Row>
      </Container>
    </div>
  );
}
