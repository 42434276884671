import React, { useState, useEffect, useContext } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./../styles/home.css";
import Carousel from "react-bootstrap/Carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStarHalfStroke } from "@fortawesome/free-solid-svg-icons";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Collapse from "react-bootstrap/Collapse";
import InputGroup from "react-bootstrap/InputGroup";
import Nav_bar_area from "./NavBar";
import { useNavigate } from "react-router-dom";

import { ContextApiContext } from "../context/ContextApi";
import { Alert } from "react-bootstrap";
import { Constant } from "../common/Constants";

import { SendRequest, SendRequestContetType } from "../common/Common";

// import Accordion from 'react-bootstrap/Accordion';
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import Map from "./Components/Map";
import Footer from "./Components/Footer";

export default function Home_Page() {
  const Tab = ({ label, isActive, onClick, imageUrl }) => {
    return (
      <>
        <Col md={2} className="tab_style">
          <button
            className={`tab ${isActive ? "active" : ""}`}
            onClick={onClick}
          >
            <img src={imageUrl} alt={`Icon for ${label}`} />
            {label}
          </button>
        </Col>
      </>
    );
  };
  const [tabs, setTabs] = useState([]);
  const { contextState, updateContextState } = useContext(ContextApiContext);
  const [open, setOpen] = useState(false);
  const [categoriesLevel1, setCategoriesLevel1] = useState([]);
  const [categoriesLevel2, setCategoriesLevel2] = useState([]);
  const [categoriesLevel3, setCategoriesLevel3] = useState([]);

  const navigate = useNavigate();

  const navigateToPath = (path, props) => {
    navigate(path, { state: props });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    initVariables();
  }, []);

  const initVariables = () => {
    setLevelOneCategories();
    setLevelTwoCategories();
    setLevelThreeCategories();
  };

  const setLevelOneCategories = async () => {
    try {
      let get_url = `${Constant.get_category_level}/1`;

      const res = await SendRequestContetType("get", get_url);

      if (res.status) {
        let categories = res.response.data;
        setCategoriesLevel1(categories);
        console.log("get response ", res);
      } else {
        updateContextState("Empty Results", "error_msg");
      }
    } catch (error) {
      updateContextState("List is empty", "error_msg");
      console.error("Error during login:", error);
    }
  };

  const setLevelTwoCategories = async () => {
    try {
      let get_url = `${Constant.get_category_level}/2`;

      const res = await SendRequestContetType("get", get_url);

      if (res.status) {
        let categories = res.response.data;
        setCategoriesLevel2(categories);
        console.log("get response ", res);
      } else {
        updateContextState("Empty Results", "error_msg");
      }
    } catch (error) {
      updateContextState("List is empty", "error_msg");
      console.error("Error during login:", error);
    }
  };

  const setLevelThreeCategories = async () => {
    try {
      let get_url = `${Constant.get_category_level}/3`;
      const res = await SendRequestContetType("get", get_url);

      if (res.status) {
        let categories = res.response.data;
        setCategoriesLevel3(categories);
        console.log("get response ", res);
      } else {
        updateContextState("Empty Results", "error_msg");
      }
    } catch (error) {
      updateContextState("List is empty", "error_msg");
      console.error("Error during login:", error);
    }
  };

  const handleTabClickLevel = (category) => {
    updateContextState({ text: category.name }, "search");
    navigateToPath("/categorydetaillisting");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const TabsComponent = ({ tabs }) => {
    return (
      <div className="tabs-container">
        <Row>
          <Button
            onClick={() => setOpen(!open)}
            className="collap "
            aria-controls="example-collapse-text"
            aria-expanded={open}
          >
            Popular Links
          </Button>
          <Collapse in={open}>
            <div className="call">
              {categoriesLevel1.map((category, index) => (
                <Tab
                  key={index}
                  label={category.name}
                  onClick={() => {
                    handleTabClickLevel(category);
                  }}
                  imageUrl={category.image_url} // Pass the imageUrl to Tab
                />
              ))}
            </div>
          </Collapse>
        </Row>
      </div>
    );
  };
  return (
    <>
      <section className="bg_bann">
        <Nav_bar_area className="colooo" />
        <Container fluid></Container>
      </section>
      <Container fluid className="tab_con">
        <Row>
          <TabsComponent tabs={tabs} />
        </Row>
      </Container>
      <div className="asda">
        <Container>
          <Row>
            {categoriesLevel2.map((category) => (
              <Col
                key={category.id}
                onClick={() => {
                  handleTabClickLevel(category);
                }}
              >
                <h5 className="line line_t">{category.name}</h5>
              </Col>
            ))}
          </Row>
        </Container>
        <section className="bg_blu">
          <Container fluid className="bg_clr_lblu">
            <Row>
              <p className="para">
                {" "}
                Use this directory to find health, social and community services
                in Toronto, including the former municipalities of East York,
                Etobicoke, North York, Scarborough and York.
                <br></br>
                If you live outside these areas, see other service directory
                options in our Commonly Asked Questions. Visit How to Search to
                learn how to use the directory.
              </p>
            </Row>
            <Row>
              {categoriesLevel3.map((category) => (
                <Col md={3} key={category.id}>
                  <div
                    className="bx"
                    onClick={() => {
                      console.log("test category.id ", category.id);
                      handleTabClickLevel(category);
                    }}
                  >
                    <div className="bx_img">
                      <img
                        className=""
                        src={category.image_url}
                        alt={category.name}
                      />
                    </div>
                  </div>
                  <div className="bx_hd">
                    <h5>{category.name}</h5>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
          <Map />
          <WelcomeHelpJobModal
            updateContextState={updateContextState}
            navigateToPath={navigateToPath}
          />
        </section>
      </div>

      <Footer />
    </>
  );
}

const WelcomeHelpJobModal = ({ updateContextState, navigateToPath }) => {
  const [helpJobModal, setHelpJobModal] = useState(true);
  const SearchList = (selectedVal) => {
    updateContextState({ text: selectedVal }, "search");
    navigateToPath("/categorydetaillisting");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <Modal
      show={helpJobModal}
      onHide={() => setHelpJobModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="bt_mdl_cnt"
    >
      <Modal.Header>
        <Modal.Title className="md_title">Looking For...?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <Button onClick={() => SearchList("Help")} className="st_mdl_btn">
                Help
              </Button>{" "}
            </Col>
            <Col>
              <Button onClick={() => SearchList("Job")} className="st_mdl_btn">
                Jobs
              </Button>{" "}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
