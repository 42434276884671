import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ErrorAlert from "./js/Components/ErrorAlert";
import Home from "./js/Home";
import { useEffect } from "react";
import Community_people from "./js/Community_people";
import ContexApiProvider from "./context/ContextApi";
import LoginModal from "./js/Components/LoginModal";
import Loader from "./js/Components/Loader";
import AddJob from "./js/AddJob";
import Register from "./js/Register";
import Listing from "./js/Listing";
import MyJobs from "./js/MyJobs";
import MyBookmarks from "./js/MyBookmarks";


import Services_payment from "./js/Services_payment";
import CategoryDetailListing from "./js/CategoryDetailListing";

function App() {

  const googleTranslateElementInit = () => {
    try {
      if (window.google && window.google.translate) {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: "en",
            autoDisplay: false,
          },
          "google_translate_element"
        );
      } else {
        console.error("Google Translate API not available");
      }
    } catch (e) {
      console.error("Google Translate API not available");
    }
  };
  useEffect(() => {
    setTimeout(() => {
      googleTranslateElementInit(); // Check if this function is called
    }, 3000);
  }, []);

  return (
    <div className="App">
      {/* <div id="google_translate_element"></div> */}
      <ContexApiProvider>
        <Router>
          <ErrorAlert />
          <Loader/>
          <LoginModal />
          {/* <Offcanvas_colapsable_nav /> */}
          <Routes>
            <Route Component={Home} path="/"></Route>
            <Route
              Component={Community_people}
              path="/community_people"
            ></Route>
            <Route
              Component={Services_payment}
              path="/services_payment"
            ></Route>
            <Route
              Component={CategoryDetailListing}
              path="/categorydetaillisting"
            ></Route>
            <Route
              Component={Register}
              path="/register"
            ></Route>
            <Route
              Component={AddJob}
              path="/addjob"
            ></Route>
            <Route
              Component={Listing}
              path="/cat"
            ></Route>
            <Route
              Component={MyJobs}
              path="/myjobs"
            ></Route>
            <Route
              Component={MyBookmarks}
              path="/mybookmarks"
            ></Route>
          </Routes>
        </Router>
      </ContexApiProvider>
    </div>
  );
}

export default App;
