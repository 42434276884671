import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContextApiContext } from "../context/ContextApi";
import { Constant } from "../common/Constants";
import { SendRequestContetType } from "../common/Common";
import Nav_bar_area from "./NavBar";
import "./../styles/Services_payment.css";
import "./../styles/categorydetaillisting.css";
import "./../styles/myjob.css";
import Modal from 'react-bootstrap/Modal';
import {
  faTrash,
  faPenToSquare,
  faSuitcaseRolling,
  faDoorOpen,
} from "@fortawesome/free-solid-svg-icons";

export default function MyJobs() {
  const { contextState, updateContextState } = useContext(ContextApiContext);
  const [myJobList, setMyJobList] = useState([]);
  const [deleteJobId, setDeleteJobId] = useState(null);
  const [show, setDeleteShow] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    initVariables();
  }, []);

  useEffect(() => {
    if (contextState.search.reload) {
      getMyJobsList();
    }
  }, [contextState.search.reload]);

  const initVariables = () => {
    getMyJobsList();
  };

  const getMyJobsList = async (page = 1) => {
    try {
      const searchFilter = contextState.search;
      updateContextState({ reload: false }, "search");

      const getSearchResultsUrl = `${Constant.get_jobs}?page=${page}`;

      const res = await SendRequestContetType(
        "get",
        getSearchResultsUrl,
        null, 
        true
      );

      if (res.status) {
        const response = res;
        console.log("myresponse" , res);
        setMyJobList(response.data);
      } else {
        updateContextState("Empty Results", "error_msg");
      }
    } catch (error) {
      updateContextState("List is empty", "error_msg");
      console.error("Error fetching job list:", error);
    }
  };

  const navigateToPath = (path, state = {}) => {
    navigate(path, { state });
  };

  const handleShowMoreClick = (result) => {
    navigateToPath("/community_people", { result });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleDeleteModalClose = () => setDeleteShow(false);
  const handleDeleteModalShow = (jobId) => {
    setDeleteJobId(jobId);
    setDeleteShow(true);
  };

  const handleDeleteJob = async () => {
    try {
      const deleteJobUrl = `${Constant.delete_job}/${deleteJobId}`;

      const res = await SendRequestContetType(
        "post",
        deleteJobUrl,
        null,
        false
      );

      if (res.status) {
        setMyJobList(myJobList.filter(job => job.id !== deleteJobId));
        updateContextState({ reload: true }, "search");
        handleDeleteModalClose();
      } else {
        updateContextState("Failed to delete job", "error_msg");
      }
    } catch (error) {
      updateContextState("Error deleting job", "error_msg");
      console.error("Error deleting job:", error);
    }
  };

  return (
    <div>
      <Nav_bar_area className="colooo" />
      <section className="bg_blu_bk">
        <Container>
          <Row>
            <Col>
              <h3 className="top_heading_page">My Jobs</h3>
            </Col>
            <Col>
              <Button
                className="dp_btn-side_jb mg_btm"
                onClick={() => navigateToPath("/addjob")}
              >
                + Job
              </Button>
            </Col>
          </Row>
        </Container>
        <div className="login_back">
          <Container>
            <div className="dggg">
              <Row className="md-4">
                <Col xs={12} md={12}>
                  <div className="hvbj"></div>
                </Col>
              </Row>
              <Row>
                {myJobList.map((result) => (
                  <Col xs={12} md={6} key={result.id}>
                    <div
                      className="box_myjob"
                      onClick={() => handleShowMoreClick(result)}
                    >
                      <Row>
                        <Col xs={12} md={9}>
                          <div className="inner-box1_myjob">
                            <h4>{result.name}</h4>
                            <h6>{result.category_name}</h6>
                            <h6>
                              {result?.distance?.toFixed(2) ?? ""}{" "}
                              {result?.distance ? "KM" : ""}
                            </h6>
                            <h6>{result.address}</h6>
                            <h6>{result.taxonomy ?? ""}</h6>
                            <div className="show_area">
                              <Button variant="" className="bt show_btn">
                                Show More
                              </Button>
                            </div>
                          </div>
                        </Col>
                        <Col xs={12} md={3}>
                          <div className="inner-box2">
                            <h5>
                              <img
                                className="logo_ft"
                                src="/images/slicing/Help101-Web.png"
                                alt="Content"
                              />
                            </h5>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="">
                      <Button variant="" className="" onClick={() => handleDeleteModalShow(result.id)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                      <Button variant="" className="" onClick={() => {
                        console.log('result111111a',{job_id:result});
                        updateContextState(
                          result,
                          "job"
                        );
                        navigateToPath("/addjob",{job_id:result})
                      }}>
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </Button>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </Container>
        </div>
      </section>
      <>
        <Modal show={show} onHide={handleDeleteModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Do you want to continue?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleDeleteModalClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleDeleteJob}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </div>
  );
}
