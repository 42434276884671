import React, { useEffect, useContext } from "react";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { ContextApiContext } from "../../context/ContextApi";
import { Constant } from "../../common/Constants";
import "./../../styles/map.css";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import Button from 'react-bootstrap/Button';


export default function Map() {
  const navigate = useNavigate();
  const location = useLocation();
  const { contextState, updateContextState } = useContext(ContextApiContext);

  const [radioValue, setRadioValue] = useState("1");

  const navigateToPath = (path) => {
    navigate(path);
  };

  const onMapLoad = () => {
    console.log("on map load");
    navigator?.geolocation.getCurrentPosition(
      ({ coords: { latitude: lat, longitude: lng } }) => {
        const pos = { lat, lng };
        console.log("ccccc", pos);
        // this.setState({ currentLocation: pos });
      }
    );
  };

  const mapRadioViews = [
    { name: "Map View", value: "1" },
    // { name: "List View", value: "2" },
  ];
  const mapStyles = {
    height: "400px",
    width: "100%",
  };

  const defaultCenter = {
    lat: contextState.search.lat, // Default latitude
    lng: contextState.search.long, // Default longitude
  };

  const [markers, setMarkers] = useState([]);

  const handleMapClick = (event) => {
    const newMarker = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };

    // setMarkers([...markers, newMarker]);
    setMarkers([newMarker]);
    updateContextState(
      {
        lat: newMarker.lat,
        long: newMarker.lng,
        reload: true,
      },
      "search"
    );

    // Log the latitude and longitude values
    console.log("Latitude update market:", newMarker.lat);
    console.log("Longitude update market:", newMarker.lng);
  };

  return (
    <div className="map_area">
      <div className="btn-toggle">
        <Button className="map_btn" variant="primary"> Close Proximity Search</Button> <br />
      </div>
      <LoadScript googleMapsApiKey={Constant.googleMapApi}>
        <GoogleMap
          onLoad={(map) => onMapLoad(map)}
          mapContainerStyle={mapStyles}
          zoom={13}
          center={defaultCenter}
          onClick={handleMapClick}
        >
          {markers.map((marker, index) => (
            <Marker
              key={index}
              position={{ lat: marker.lat, lng: marker.lng }}
            />
          ))}
        </GoogleMap>
      </LoadScript>
    </div>
  );
}
