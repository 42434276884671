import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import Nav_bar_area from "./NavBar";
import Map from "./Components/Map";
import Footer from "./Components/Footer";
import { useLocation } from "react-router-dom";
import "./../styles/Community_people.css";

export default function Communityeople() {
  const location = useLocation();
  const [listDetails, setlistDetails] = useState({});

  useEffect(() => {
    const result = location.state?.result || {};
    setlistDetails(result);
  }, [location.state]);

  const ensureProtocol = (url) => {
    if (!url) return "#"; // Return a fallback or empty URL if none is provided
    if (/^https?:\/\//i.test(url)) return url;
    return `http://${url}`;
  };

  const printPage = () => {
    window.print();
  };

  return (
    <div>
      <Nav_bar_area className="colooo" />
      <Container fluid className="bg_clr_lbluu">
        <Row>
          <Col xs={12} md={12}>
            <div className="top-hd">
              <h1>{listDetails.category_name}</h1>
            </div>
            <div className="btn_area_fav">
              <Button className="fav" onClick={printPage}>
                Print <FontAwesomeIcon icon={faPrint} />
              </Button>
              {listDetails?.website && (
                <a href={ensureProtocol(listDetails?.website)} target="_blank" rel="noopener noreferrer">
                  <Button className="fav">Apply Now</Button>
                </a>
              )}
            </div>

            <div className="random_txt">
              <div>
                <ul>
                  {listDetails?.website && (
                    <li>
                      <a href={ensureProtocol(listDetails?.website)}>
                        <b>Visit our website</b>
                      </a>
                    </li>
                  )}
                  {listDetails?.email && (
                    <li>
                      <b>Email</b> : {listDetails?.email}
                    </li>
                  )}
                  {listDetails?.salary && (
                    <li>
                      <b>Salary</b> : {listDetails?.salary}
                    </li>
                  )}
                  {listDetails?.taxonomy && (
                    <li>
                      <b>Company Description</b> : {listDetails?.taxonomy}
                    </li>
                  )}
                  {listDetails?.type && (
                    <li>
                      <b>Type</b> : {listDetails?.type}
                    </li>
                  )}
                  {listDetails?.distance && (
                    <li>
                      <b>Distance</b> : {listDetails.distance.toFixed(2)} KM
                    </li>
                  )}
                </ul>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: listDetails?.description,
                }}
              ></div>
            </div>

            <Row>
              <Col>
                <Map />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}
