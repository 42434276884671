import React, { useEffect, useContext } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./../styles/Community_people.css";
import Carousel from "react-bootstrap/Carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Alert from "react-bootstrap/Alert";
import {
  faSliders,
  faUser,
  faSuitcaseRolling,
  faDoorOpen,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";

import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";

import InputGroup from "react-bootstrap/InputGroup";
import Nav_bar_area from "./NavBar";
import { useNavigate, useLocation } from "react-router-dom";
import { Constant } from "../common/Constants";
import { ContextApiContext } from "../context/ContextApi";
import Common, {
  googleTranslate,
  SendRequest,
  SendRequestContetType,
} from "../common/Common";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Map from "./Components/Map";
import Footer from "./Components/Footer";

export default function CategoryResultListing() {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToPath = (path) => {
    navigate(path);
  };
  
  return (
    <div>
      <Nav_bar_area className="colooo" />
      <Container fluid className="bg_clr_lbluu">
      <Container  className="">
            <div clasName="tsop-hd">
              <h1>listDetails.category_name</h1>
            </div>

            <div className="random_txt">
               <div>
                  <ul>
                    <Row>

                      <Col xs={12} md={6}>
     
                      </Col>
                    </Row>
                  
                  </ul>
               </div>
              <div
                
              ></div>
            </div>

            <Row>
              <Col>
                <Map />
              </Col>
            </Row>
      </Container>
      </Container>
      <Footer />
    </div>
  );
}
