import React, { useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./../styles/register.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import { Constant } from "../common/Constants";
import { ContextApiContext } from "../context/ContextApi";
import { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Common, {
  googleTranslate,
  SendRequest,
  SendRequestContetType,
} from "../common/Common";
import Nav_bar_area from "./NavBar";
import StepWizard from "react-step-wizard";
import Dropdown from "react-bootstrap/Dropdown";

export default function AddJob(addJobprops) {
  const navigate = useNavigate();
  const navigateToPath = (path) => {
    navigate(path);
  };
  const location = useLocation;
  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const jobId = location.state?.job_id ?? 0;

  useEffect(() => {
    console.log("category", category, setCategory);
    console.log("settingcategory", setCategory);
    console.log("init search1111");
    getCategory();
  }, []);

  useEffect(() => {
    get_job_edit();
  }, [jobId, addJobprops.updateContextState]);

  const getCategory = async () => {
    try {
      const response = await fetch(`${Constant.get_category_level}/3`);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      console.log(data);
      setCategory(data.response.data);
      setLoading(false); // Update loading state after data is fetched
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false); // Update loading state in case of error
    }
  };
  const [show, setShow] = useState(false);
  const { contextState, updateContextState } = useContext(ContextApiContext);
  //step1

  // const [companyIndustry, setCompanyIndustry] = useState("");
  const [lat, setlat] = useState("");
  const [long, setlong] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [numberOfJobs, setNumberOfJobs] = useState("");
  const [jobLOcationDiscription, setJobLOcationDiscription] = useState("");
  const [companyDiscription, setCompanyDiscription] = useState("");
  const [generateJobPost, setGenerateJobPost] = useState("");
  const [isEnglishRequired, setIsEnglishRequired] = useState(false);
  const [isFrenchRequired, setIsFrenchRequired] = useState("");
  const [isLanguageTraining, setIsLanguageTraining] = useState("");
  //step1

  //step2
  const [isFullTime, setIsFullTime] = useState("");
  const [isPartTime, setIsPartTime] = useState("");
  const [isPermanent, setIsPermanent] = useState("");
  const [isFixedTermContract, setIsFixedTermContract] = useState("");
  const [isCasual, setIsCasual] = useState("");
  const [isSeasonal, setIsSeasonal] = useState("");
  const [isFreelance, setIsFreelance] = useState("");
  const [isApprenticeship, setIsApprenticeship] = useState("");
  const [isInternship, setIsInternship] = useState("");
  const [expectedHours, setExpectedHours] = useState("");
  const [isMondayToFriday, setIsMondayToFriday] = useState("");
  const [is8hourshift, setIs8hourshift] = useState("");
  const [isDayShift, setIsDayShift] = useState("");
  const [isEveningShift, setIsEveningShift] = useState("");
  const [isNightshift, setIsNightshift] = useState("");
  const [isMorningshift, setIsMorningshift] = useState("");
  const [isOvertime, setIsOvertime] = useState("");
  const [isOnCall, setIsOnCall] = useState("");
  const [isNoWeekends, setIsNoWeekends] = useState("");
  const [isEveryWeekend, setIsEveryWeekend] = useState("");
  const [isHolidays, setIsHolidays] = useState("");
  const [is10HourShift, setIs10HourShift] = useState("");
  const [is12HourShift, setIs12HourShift] = useState("");
  const [is4HourShift, setIs4HourShift] = useState("");
  const [isWeekendsOnly, setIsWeekendsOnly] = useState("");
  const [isOtherShift, setIsOtherShift] = useState("");
  const [contractLenght, setContractLenght] = useState("");
  const [isPlannedStartDate, setIsPlannedStartDate] = useState("");
  //step2

  //step3
  const [minimumCA, setMinimumCA] = useState("");
  const [maximumCA, setMaximumCA] = useState("");
  const [fixedAtHours, setFixedAtHours] = useState("");
  const [isOverTimePay, setIsOverTimePay] = useState("");
  const [isBonusPay, setIsBonusPay] = useState("");
  const [isTips, setIsTips] = useState("");
  const [isCommissionPay, setIsCommissionPay] = useState("");
  const [isSigningBonus, setIsSigningBonus] = useState("");
  const [isRetentionBonus, setIsRetentionBonus] = useState("");
  const [isOtherBonus, setIsOtherBonus] = useState("");
  const [isDentalCare, setIsDentalCare] = useState("");
  const [isExtendedHealthCare, setIsExtendedHealthCare] = useState("");
  const [isVisionCare, setIsVisionCare] = useState("");
  const [isLifeInsurance, setIsLifeInsurance] = useState("");
  const [isRRSPMatch, setIsRRSPMatch] = useState("");
  const [isPaidTimeOff, setIsPaidTimeOff] = useState("");
  const [isOnSiteParking, setIsOnSiteParking] = useState("");
  const [isEmployeeAssistanceProgram, setIsEmployeeAssistanceProgram] =
    useState("");
  const [isDisabilityInsurance, setIsDisabilityInsurance] = useState("");
  const [isCasualDress, setIsCasualDress] = useState("");
  const [isCompanyEvents, setIsCompanyEvents] = useState("");
  const [isStoreDiscount, setIsStoreDiscount] = useState("");
  const [isCompanyPension, setIsCompanyPension] = useState("");
  const [isTuitionReimbursement, setIsTuitionReimbursement] = useState("");
  const [isWellnessProgram, setIsWellnessProgram] = useState("");
  const [isDiscountedOrFreeFood, setIsDiscountedOrFreeFood] = useState("");
  const [isWorkFromHome, setIsWorkFromHome] = useState("");
  const [isProfitSharing, setIsProfitSharing] = useState("");
  const [isOnSiteGym, setIsOnSiteGym] = useState("");
  const [isEmployeeStockPurchasePlan, setIsEmployeeStockPurchasePlan] =
    useState("");
  const [isRelocationAssistance, setIsRelocationAssistance] = useState("");
  const [isCompanyCar, setIsCompanyCar] = useState("");
  const [isStockOptions, setIsStockOptions] = useState("");
  const [isCommuterBenefits, setIsCommuterBenefits] = useState("");
  const [isHousingAllowance, setIsHousingAllowance] = useState("");
  const [isAutomobileAllowance, setIsAutomobileAllowance] = useState("");
  const [isLanguageTrainingProvided, setIsLanguageTrainingProvided] =
    useState("");
  const [isOnSiteChildcare, setIsOnSiteChildcare] = useState("");
  const [isVRSP, setIsVRSP] = useState("");
  const [isFlexibleSchedule, setIsFlexibleSchedule] = useState("");
  const [isOtherBenefits, setIsOtherBenefits] = useState("");
  //step3

  //step4
  const [emailUpdate, setEmailUpdate] = useState("");
  const [email, setEmail] = useState("");
  const [byPhone, setByPhone] = useState("");
  const [isResume, setIsResume] = useState("");
  const [isApplicationDeadline, setIsApplicationDeadline] = useState("");
  const [isHiringTimeline, setIsHiringTimeline] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedItem, setSelectedCategory] = useState("");
  const [job, setJob] = useState({
    id: 0,
    category_name: "",
    result_json_format: {},
  });
  const [categoryId, setCategoryId] = useState(0);
  const [categoryName, setCategoryName] = useState("");
  const [categoryResultId, setcategoryResultId] = useState("");

  // const setResultJsonFormat= (result_json_format) => {
  //   let update_job= job;
  //   update_job.result_json_format = result_json_format;
  //   setJob({...job,update_job});
  // }

  const setResultJsonFormat = (result_json_format) => {
    setJob((prevJob) => ({
      ...prevJob,
      result_json_format: {
        ...prevJob.result_json_format,
        ...result_json_format,
      },
    }));
  };

  const getBoolValue = (e) => {
    let boolVal = e.target.checked ? "1" : "0";
    console.log("boolValboolValboolVal", boolVal);
    return boolVal;
  };

  const get_job_edit = async () => {
    try {
      console.log("location:", location);
      console.log("location state:", location.state);
      console.log("location state job_id:", location.state?.job_id);
      let job = contextState.job;

      const res = await SendRequest(
        "GET",
        `${Constant.job}/${job.id}`,
        null,
        true
      );
      console.log("get job", res);

      if (res.status) {
        console.log("get job ", res.response);
        setJob(res.response);
        setCategoryId(res.response.category_id);
      } else {
        console.log("resss", res);

        if (res.error.custom_code === 403) {
          console.log("403 error");
          updateContextState(true, "show_login_modal");
          updateContextState("Please Login and try again", "error_msg");
        }

        updateContextState(res.error?.message[0], "error_msg");
      }
    } catch (error) {
      console.error("Error updating agent:", error);
    }
  };
  // let categoryResultId;
  console.log("categoryResultId this here",categoryResultId);

  const add_job_step_1 = async () => {
    try {
      console.log("latssssssssssssssssssss", job.lat);
      // console.log("category_id sakjdsajhdjahskjdzzzzzzzhgj", category_id);
      // return;
      // console.log("numberOfEmployee", numberOfEmployee);
      // console.log("firstName", firstName);
      // console.log("lastName", lastName);
      // console.log("isManager", isManager);
      // console.log("isReportingChain", isReportingChain);
      // console.log("heardUS", heardUS);
      // console.log("phoneNumber", phoneNumber);

      var formData = new FormData();
      // formData.append("company_id", 1);
      formData.append("step_number", "1");
      formData.append("category_job_id", job.id);
      formData.append("category_id", categoryId);
      formData.append("category_name", job.category_name);
      formData.append("long", job.long);
      formData.append("lat", job.lat);
      formData.append("name", job.name);
      formData.append("number_of_jobs", job.result_json_format.number_of_jobs);
      formData.append(
        "job_location_discription",
        job.result_json_format.job_location_discription
      );
      formData.append(
        "company_discription",
        job.result_json_format.company_discription
      );
      formData.append(
        "generate_job_post",
        job.result_json_format.generate_job_post
      );
      formData.append(
        "is_english_required",
        job.result_json_format.is_english_required
      );
      formData.append(
        "is_french_required",
        job.result_json_format.is_french_required
      );
      formData.append(
        "is_language_training",
        job.result_json_format.is_language_training
      );
      // is_english_required: job.result_json_format.is_english_required ? 1: 0,

      let cs = contextState;

      const res = await SendRequest(
        "POST",
        Constant.add_job_step1,
        formData,
        true
      );

      console.log("rrrrrrrrrrr", res);
      if (res.status) {
        console.log("sign ", res.response);
        setcategoryResultId(res.response.id); 
        console.log("categoryResultId set to:", categoryResultId);


      } else {
        console.log("resss", res);
        if (res.error.custom_code == 403) {
          console.log("403 error");
          updateContextState(true, "show_login_modal");
          updateContextState("Please Login and try again", "error_msg");

          // navigateToPath(-1);
        }
        updateContextState(res.error?.message[0], "error_msg");
      }
    } catch (error) {
      console.error("Error updating agent :", error);
    }
  };
  const add_job_step2 = async () => {
    try {
      if (!categoryResultId) {
        console.error("categoryResultId is not set. Make sure step 1 is completed successfully.");
        return;
      }
      console.log("category_id sakjdsajhdjahskjdhgj", categoryId);
      console.log("is_full_time", job.result_json_format.is_full_time);
      console.log("is_part_time", job.result_json_format.is_part_time);
      console.log("is_permanent", job.result_json_format.is_permanent);
      console.log(
        "is_fixed_term_contract",
        job.result_json_format.is_fixed_term_contract
      );
      console.log("is_casual", job.result_json_format.is_casual);
      console.log("is_seasonal", job.result_json_format.is_seasonal);
      console.log("is_freelance", job.result_json_format.is_freelance);
      console.log(
        "is_apprenticeship",
        job.result_json_format.is_apprenticeship
      );
      console.log("is_internship", job.result_json_format.is_internship);
      console.log("expected_hoursaaaaaaaaa", job.result_json_format.expected_hours);
      console.log("is_monday_to_friday", isMondayToFriday);
      console.log("is8hour_shift", is8hourshift);
      console.log("is_day_shift", isDayShift);
      console.log("is_evening_shift", isEveningShift);
      console.log("is_night_shift", isNightshift);
      console.log("is_morning_shift", isMorningshift);
      console.log("is_overtime", isOvertime);
      console.log("is_on_call", isOnCall);
      console.log("is_no_weekends", isNoWeekends);
      console.log("is_everyWeekend ", isEveryWeekend);
      console.log("is10hour_shift", is10HourShift);
      console.log("is12hour_Shift", is12HourShift);
      console.log("is4hour_shift", is4HourShift);
      console.log("is_weekends_only", isWeekendsOnly);
      console.log("is_other_shift", isOtherShift);
      console.log("contract_lenght", job.result_json_format.contract_lenght);
      console.log("is_planned_start_date", isPlannedStartDate);

      var formData = new FormData();
      formData.append("step_number", 2);
      formData.append("category_result_id", categoryResultId);
      formData.append("category_job_id", job.id);
      formData.append("category_id", categoryId);
      formData.append("is_full_time", job.result_json_format.is_full_time);
      formData.append("is_part_time", job.result_json_format.is_part_time);
      formData.append("is_permanent", job.result_json_format.is_permanent);
      formData.append(
        "is_fixed_term_contract",
        job.result_json_format.is_fixed_term_contract
      );
      formData.append("is_casual", job.result_json_format.is_casual);
      formData.append("is_seasonal", job.result_json_format.is_seasonal);
      formData.append("is_freelance", job.result_json_format.is_freelance);
      formData.append(
        "is_apprenticeship",
        job.result_json_format.is_apprenticeship
      );
      formData.append("is_internship", job.result_json_format.is_internship);
      formData.append("expected_hours", job.result_json_format.expected_hours);
      formData.append(
        "is_monday_to_friday",
        job.result_json_format.is_monday_to_friday
      );
      formData.append("is8hour_shift", job.result_json_format.is8hour_shift);
      formData.append("is_day_shift", job.result_json_format.is_day_shift);
      formData.append(
        "is_evening_shift",
        job.result_json_format.is_evening_shift
      );
      formData.append("is_night_shift", job.result_json_format.is_night_shift);
      formData.append(
        "is_morning_shift",
        job.result_json_format.is_morning_shift
      );
      formData.append("is_overtime", job.result_json_format.is_overtime);
      formData.append("is_on_call", job.result_json_format.is_on_call);
      formData.append("is_no_weekends", job.result_json_format.is_no_weekends);
      formData.append(
        "is_everyWeekend ",
        job.result_json_format.is_everyWeekend
      );
      formData.append("is_holiday ", job.result_json_format.is_holiday);
      formData.append("is10hour_shift", job.result_json_format.is10hour_shift);
      formData.append("is12hour_Shift", job.result_json_format.is12hour_Shift);
      formData.append("is4hour_shift", job.result_json_format.is4hour_shift);
      formData.append(
        "is_weekends_only",
        job.result_json_format.is_weekends_only
      );
      formData.append("is_other_shift", job.result_json_format.is_other_shift);
      formData.append("contract_lenght", job.result_json_format.contract_lenght);
      formData.append(
        "is_planned_start_date",
        job.result_json_format.is_planned_start_date
      );

      let cs = contextState;

      const res = await SendRequest(
        "POST",
        Constant.add_job_step1,
        formData,
        true
      );

      console.log("rrrrrrrrrrr", res);
      if (res.status) {
        console.log("sign ", res.response);
        let response = res.response;
        // setJob(response);
      } else {
        console.log("resss", res);
        if (res.error.custom_code == 403) {
          console.log("403 error");
          updateContextState(true, "show_login_modal");
          updateContextState("Please Login and try again", "error_msg");

          // navigateToPath(-1);
        }
        updateContextState(res.error?.message[0], "error_msg");
      }
    } catch (error) {
      console.error("Error updating agent :", error);
    }
  };
  const add_job_step3 = async () => {
    try {
      console.log("category_id sakjdsajhdjahskjdhgj", categoryId);
      console.log("minimum_ca", minimumCA);
      console.log("maximum_ca", maximumCA);
      console.log("fixed_at_hours", fixedAtHours);
      console.log("is_over_time_pay", isOverTimePay);
      console.log("is_bonus_pay", isBonusPay);
      console.log("is_tips", isTips);
      console.log("is_commission_pay", isCommissionPay);
      console.log("is_signing_bonus", isSigningBonus);
      console.log("is_retention_bonus", isRetentionBonus);
      console.log("is_other_bonus", isOtherBonus);
      console.log("is_dental_care", isDentalCare);
      console.log("is_extended_health_care", isExtendedHealthCare);
      console.log("is_vision_care", isVisionCare);
      console.log("is_life_insurance", isLifeInsurance);
      console.log("is_rrsp_match", isRRSPMatch);
      console.log("is_morning_shift", isMorningshift);
      console.log("is_paid_time_off", isPaidTimeOff);
      console.log("is_on_site_parking", isOnSiteParking);
      console.log(
        "is_employee_assistance_program",
        isEmployeeAssistanceProgram
      );

      console.log("is_disability_insurance", isDisabilityInsurance);
      console.log("is_casual_dress", isCasualDress);
      console.log("is_company_events", isCompanyEvents);
      console.log("is_store_discount", isStoreDiscount);
      console.log("is_company_pension", isCompanyPension);
      console.log("is_tuition_reimbursement", isTuitionReimbursement);
      console.log("is_wellness_program", isWellnessProgram);
      console.log("is_discounted_or_freefood", isDiscountedOrFreeFood);
      console.log("is_work_from_home", isWorkFromHome);
      console.log("is_profit_sharing", isProfitSharing);
      console.log("is_on_site_gym", isOnSiteGym);
      console.log(
        "is_employee_stock_purchase_plan",
        isEmployeeStockPurchasePlan
      );
      console.log("is_relocation_assistance", isRelocationAssistance);
      console.log("is_company_car", isCompanyCar);
      console.log("isStockOptions", isStockOptions);
      console.log("is_commuter_benefits", isCommuterBenefits);
      console.log("is_housing_allowance", isHousingAllowance);
      console.log("is_automobile_allowance", isAutomobileAllowance);
      console.log("is_language_training_provided", isLanguageTrainingProvided);
      console.log("is_on_site_childcare", isOnSiteChildcare);
      console.log("is_vrsp", isVRSP);
      console.log("is_flexible_schedule", isFlexibleSchedule);
      console.log("is_other_benefits", isOtherBenefits);

      var formData = new FormData();
      formData.append("step_number", 3);
      formData.append("category_result_id", categoryResultId);
      formData.append("category_job_id", job.id);
      formData.append("category_id", categoryId);
      formData.append("minimum_ca", job.result_json_format.minimum_ca);
      formData.append("maximum_ca", job.result_json_format.maximum_ca);
      formData.append("fixed_at_hours", job.result_json_format.fixed_at_hours);
      formData.append(
        "is_over_time_pay",
        job.result_json_format.is_over_time_pay
      );
      formData.append("is_bonus_pay", job.result_json_format.is_bonus_pay);
      formData.append("is_tips", job.result_json_format.is_tips);
      formData.append(
        "is_commission_pay",
        job.result_json_format.is_commission_pay
      );
      formData.append(
        "is_signing_bonus",
        job.result_json_format.is_signing_bonus
      );
      formData.append(
        "is_retention_bonus",
        job.result_json_format.is_retention_bonus
      );
      formData.append("is_other_bonus", job.result_json_format.is_other_bonus);
      formData.append("is_dental_care", job.result_json_format.is_dental_care);
      formData.append(
        "is_extended_health_care",
        job.result_json_format.is_extended_health_care
      );
      formData.append("is_vision_care", job.result_json_format.is_vision_care);
      formData.append(
        "is_life_insurance",
        job.result_json_format.is_life_insurance
      );
      formData.append("is_rrsp_match", job.result_json_format.is_rrsp_match);
      formData.append(
        "is_morning_shift",
        job.result_json_format.is_morning_shift
      );
      formData.append(
        "is_paid_time_off",
        job.result_json_format.is_paid_time_off
      );
      formData.append(
        "is_on_site_parking",
        job.result_json_format.is_on_site_parking
      );
      formData.append(
        "is_employee_assistance_program",
        job.result_json_format.is_employee_assistance_program
      );
      formData.append(
        "is_disability_insurance",
        job.result_json_format.is_disability_insurance
      );
      formData.append(
        "is_casual_dress",
        job.result_json_format.is_casual_dress
      );
      formData.append(
        "is_company_events",
        job.result_json_format.is_company_events
      );
      formData.append(
        "is_store_discount",
        job.result_json_format.is_store_discount
      );
      formData.append(
        "is_company_pension",
        job.result_json_format.is_company_pension
      );
      formData.append(
        "is_tuition_reimbursement",
        job.result_json_format.is_tuition_reimbursement
      );
      formData.append(
        "is_wellness_program",
        job.result_json_format.is_wellness_program
      );
      formData.append(
        "is_discounted_or_freefood",
        job.result_json_format.is_discounted_or_freefood
      );
      formData.append(
        "is_work_from_home",
        job.result_json_format.is_work_from_home
      );
      formData.append(
        "is_profit_sharing",
        job.result_json_format.is_profit_sharing
      );
      formData.append("is_on_site_gym", job.result_json_format.is_on_site_gym);
      formData.append(
        "is_employee_stock_purchase_plan",
        job.result_json_format.is_employee_stock_purchase_plan
      );
      formData.append(
        "is_relocation_assistance",
        job.result_json_format.is_relocation_assistance
      );
      formData.append("is_company_car", job.result_json_format.is_company_car);
      formData.append("isStockOptions", job.result_json_format.isStockOptions);
      formData.append(
        "is_commuter_benefits",
        job.result_json_format.is_commuter_benefits
      );
      formData.append(
        "is_housing_allowance",
        job.result_json_format.is_housing_allowance
      );
      formData.append(
        "is_automobile_allowance",
        job.result_json_format.is_automobile_allowance
      );
      formData.append(
        "is_language_training_provided",
        job.result_json_format.is_language_training_provided
      );
      formData.append(
        "is_on_site_childcare",
        job.result_json_format.is_on_site_childcare
      );
      formData.append("is_vrsp", job.result_json_format.is_vrsp);
      formData.append(
        "is_flexible_schedule",
        job.result_json_format.is_flexible_schedule
      );
      formData.append(
        "is_other_benefits",
        job.result_json_format.is_other_benefits
      );

      let cs = contextState;

      const res = await SendRequest(
        "POST",
        Constant.add_job_step1,
        formData,
        true
      );

      console.log("rrrrrrrrrrr", res);
      if (res.status) {
        console.log("sign ", res.response);
        let response = res.response;
        // setJob(response);
      } else {
        console.log("resss", res);
        if (res.error.custom_code == 403) {
          console.log("403 error");
          updateContextState(true, "show_login_modal");
          updateContextState("Please Login and try again", "error_msg");

          // navigateToPath(-1);
        }
        updateContextState(res.error?.message[0], "error_msg");
      }
    } catch (error) {
      console.error("Error updating agent :", error);
    }
  };
  const add_job_step4 = async () => {
    try {
      console.log("category_id sakjdsajhdjahskjdhgj", categoryId);
      console.log("email_update", emailUpdate);
      console.log("email", email);
      console.log("by_phone", byPhone);
      console.log("is_resume", isResume);
      console.log("is_application_deadline", isApplicationDeadline);
      console.log("hiring_timeline", isHiringTimeline);
      console.log("current_step", currentStep);

      var formData = new FormData();
      formData.append("step_number", 4);
      formData.append("category_result_id", categoryResultId);
      formData.append("category_job_id", job.id);
      formData.append("category_id", categoryId);
      formData.append("email_update", job.result_json_format.email_update);
      formData.append("email", job.result_json_format.email);
      formData.append("by_phone", job.result_json_format.by_phone);
      formData.append("is_resume", job.result_json_format.is_resume);
      formData.append(
        "is_application_deadline",
        job.result_json_format.is_application_deadline
      );
      formData.append("hiring_timeline", job.result_json_format.hiring_timeline);
      formData.append("current_step", currentStep);

      let cs = contextState;

      const res = await SendRequest(
        "POST",
        Constant.add_job_step1,
        formData,
        true
      );

      console.log("rrrrrrrrrrr", res);
      if (res.status) {
        console.log("sign ", res.response);
        let response = res.response;
        // setJob(response);
        navigateToPath("/");
      } else {
        console.log("resss", res);
        if (res.error.custom_code == 403) {
          console.log("403 error");
          updateContextState(true, "show_login_modal");
          updateContextState("Please Login and try again", "error_msg");

          // navigateToPath(-1);
        }
        updateContextState(res.error?.message[0], "error_msg");
      }
    } catch (error) {
      console.error("Error updating agent :", error);
    }
  };
  return (
    <div>
      <Nav_bar_area className="colooo" />
      <section className="bg_blu_bk">
        <Container>
          <Row>
            <Col>
              <h3 className="top_heading_page">Add Job</h3>
            </Col>
          </Row>
        </Container>
        <div className="login_back">
          <Container>
            <div className="whl_login">
              <Row>
                <StepWizard>
                  <Step1
                    add_job_step_1={add_job_step_1}
                    getCategory={getCategory}
                    setSelectedCategory={setSelectedCategory}
                    category={category}
                    selectedItem={selectedItem}
                    setCategoryId={setCategoryId}
                    setCategoryName={setCategoryName}
                    // setCompanyIndustry={setCompanyIndustry}
                    setlong={setlong}
                    setlat={setlat}
                    setJobTitle={setJobTitle}
                    setNumberOfJobs={setNumberOfJobs}
                    setCompanyDiscription={setCompanyDiscription}
                    setJobLOcationDiscription={setJobLOcationDiscription}
                    setGenerateJobPost={setGenerateJobPost}
                    setIsEnglishRequired={setIsEnglishRequired}
                    setIsFrenchRequired={setIsFrenchRequired}
                    setIsLanguageTraining={setIsLanguageTraining}
                    job={job}
                    setJob={setJob}
                    setResultJsonFormat={setResultJsonFormat}
                    getBoolValue={getBoolValue}
                  />

                  <Step2
                    job={job}
                    setJob={setJob}
                    add_job_step2={add_job_step2}
                    setIsFullTime={setIsFullTime}
                    setIsPartTime={setIsPartTime}
                    setIsPermanent={setIsPermanent}
                    setIsFixedTermContract={setIsFixedTermContract}
                    setIsCasual={setIsCasual}
                    setIsSeasonal={setIsSeasonal}
                    setIsFreelance={setIsFreelance}
                    setIsApprenticeship={setIsApprenticeship}
                    setIsInternship={setIsInternship}
                    setExpectedHours={setExpectedHours}
                    setIsMondayToFriday={setIsMondayToFriday}
                    setIs8hourshift={setIs8hourshift}
                    setIsDayShift={setIsDayShift}
                    setIsEveningShift={setIsEveningShift}
                    setIsNightshift={setIsNightshift}
                    setIsMorningshift={setIsMorningshift}
                    setIsOvertime={setIsOvertime}
                    setIsOnCall={setIsOnCall}
                    setIsNoWeekends={setIsNoWeekends}
                    setIsEveryWeekend={setIsEveryWeekend}
                    setIsHolidays={setIsHolidays}
                    setIs10HourShift={setIs10HourShift}
                    setIs12HourShift={setIs12HourShift}
                    setIs4HourShift={setIs4HourShift}
                    setIsWeekendsOnly={setIsWeekendsOnly}
                    setIsOtherShift={setIsOtherShift}
                    setContractLenght={setContractLenght}
                    setIsPlannedStartDate={setIsPlannedStartDate}
                    getBoolValue={getBoolValue}
                    setResultJsonFormat={setResultJsonFormat}
                  />

                  <Step3
                    job={job}
                    add_job_step3={add_job_step3}
                    setMinimumCA={setMinimumCA}
                    setMaximumCA={setMaximumCA}
                    setFixedAtHours={setFixedAtHours}
                    setIsOverTimePay={setIsOverTimePay}
                    setIsBonusPay={setIsBonusPay}
                    setIsTips={setIsTips}
                    setIsCommissionPay={setIsCommissionPay}
                    setIsSigningBonus={setIsSigningBonus}
                    setIsRetentionBonus={setIsRetentionBonus}
                    setIsOtherBonus={setIsOtherBonus}
                    setIsDentalCare={setIsDentalCare}
                    setIsExtendedHealthCare={setIsExtendedHealthCare}
                    setIsVisionCare={setIsVisionCare}
                    setIsLifeInsurance={setIsLifeInsurance}
                    setIsRRSPMatch={setIsRRSPMatch}
                    setIsPaidTimeOff={setIsPaidTimeOff}
                    setIsOnSiteParking={setIsOnSiteParking}
                    setIsEmployeeAssistanceProgram={
                      setIsEmployeeAssistanceProgram
                    }
                    setIsDisabilityInsurance={setIsDisabilityInsurance}
                    setIsCasualDress={setIsCasualDress}
                    setIsCompanyEvents={setIsCompanyEvents}
                    setIsStoreDiscount={setIsStoreDiscount}
                    setIsCompanyPension={setIsCompanyPension}
                    setIsTuitionReimbursement={setIsTuitionReimbursement}
                    setIsWellnessProgram={setIsWellnessProgram}
                    setIsDiscountedOrFreeFood={setIsDiscountedOrFreeFood}
                    setIsWorkFromHome={setIsWorkFromHome}
                    setIsProfitSharing={setIsProfitSharing}
                    setIsOnSiteGym={setIsOnSiteGym}
                    setIsEmployeeStockPurchasePlan={
                      setIsEmployeeStockPurchasePlan
                    }
                    setIsRelocationAssistance={setIsRelocationAssistance}
                    setIsCompanyCar={setIsCompanyCar}
                    setIsStockOptions={setIsStockOptions}
                    setIsCommuterBenefits={setIsCommuterBenefits}
                    setIsHousingAllowance={setIsHousingAllowance}
                    setIsAutomobileAllowance={setIsAutomobileAllowance}
                    setIsLanguageTrainingProvided={
                      setIsLanguageTrainingProvided
                    }
                    setIsOnSiteChildcare={setIsOnSiteChildcare}
                    setIsVRSP={setIsVRSP}
                    setIsFlexibleSchedule={setIsFlexibleSchedule}
                    setIsOtherBenefits={setIsOtherBenefits}
                    getBoolValue={getBoolValue}
                    setResultJsonFormat={setResultJsonFormat}
                    setJob={setJob}
                  />
                  <Step4
                    job={job}
                    add_job_step4={add_job_step4}
                    setEmailUpdate={setEmailUpdate}
                    setEmail={setEmail}
                    setByPhone={setByPhone}
                    setIsResume={setIsResume}
                    setIsApplicationDeadline={setIsApplicationDeadline}
                    setIsHiringTimeline={setIsHiringTimeline}
                    setCurrentStep={setCurrentStep}
                    getBoolValue={getBoolValue}
                    setResultJsonFormat={setResultJsonFormat}
                    setJob={setJob}
                  />
                </StepWizard>
              </Row>
              <Row>
                <Col></Col>
                <Col></Col>
              </Row>
            </div>
          </Container>
        </div>
      </section>
    </div>
  );
}
const Step1 = (props) => {
  const add_job_step_1 = props.add_job_step_1;
  const categories = props.category;
  const selectedItem = props.selectedItem;
  const job = props.job;
  // const [isEnglishRequired,setIsEnglishRequired] = useState(props.isEnglishRequired);
  // const [isHousingAllowance, setIsHousingAllowance] = useState("");

  const [selectedCategory, setSelectedCategory] = useState({});

  // const onChangeEnglishRequired =(e)=>{
  //   setIsEnglishRequired(e.target.checked);
  //   props.setIsEnglishRequired(e.target.checked)
  // }

  // Function to handle item selection
  const handleSelect = (category_index) => {
    console.log("bb  aaa", category_index);
    console.log("categoriescategories", categories);
    if (category_index == -1) {
      setSelectedCategory(0);
    } else {
      setSelectedCategory(categories[category_index].id);
      let cat_id = categories[category_index].id;
      let cat_name = categories[category_index].name;
      console.log("cat_idcat_id  aaa", category_index);

      props.setCategoryId(cat_id);
      props.setCategoryName(cat_name);
      props.setJob({ ...job, category_name: cat_name });
    }
  };
  const [selectedLocation, setSelectedLocation] = useState("Select"); // Initialize state with default value

  // const handleSelectedLocation = (eventKey) => {
  //   setSelectedLocation(eventKey);
  //   props.setJobLOcationDiscription(eventKey);
  // };
  const handleSelectedLocation = (eventKey) => {
    props.setJob({
      ...job,
      result_json_format: {
        ...job.result_json_format,
        job_location_discription: eventKey,
      },
    });
  };

  return (
    <div>
      <div>
        <div className="wiz_head">
          <h2>Step {props.currentStep}</h2>
          <h3>Add job basics</h3>
          <p>Total Steps: {props.totalSteps}</p>
        </div>
        <Row>
          <Col md={6} xs={12}>
            {" "}
            <Form.Label htmlFor="basic-url">Company's industry * </Form.Label>
            <Dropdown
              className="drp_dn_category"
              onSelect={(category) => handleSelect(category)}
              // value={12}
            >
              <Dropdown.Toggle
                variant="success"
                className="drop_dn"
                id="dropdown-basic"
              >
                {job.category_name}
              </Dropdown.Toggle>
              <Dropdown.Menu className="dp_menu">
                <Dropdown.Item
                  value="Select a Category"
                  eventKey={-1}
                  className="dp_items"
                >
                  Select a Category
                </Dropdown.Item>
                {categories.map((category, index) => (
                  <Dropdown.Item eventKey={index} className="dp_items">
                    {category.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col md={3} xs={6}>
            {" "}
            <Form.Label htmlFor="basic-url">Latitude *</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                type="number"
                id="basic-url"
                onChange={(e) => props.setJob({ ...job, lat: e.target.value })}
                aria-describedby="basic-addon3"
                value={job.lat}
              />
            </InputGroup>
          </Col>
          <Col md={3} xs={6}>
            {" "}
            <Form.Label htmlFor="basic-url">Longitude *</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
              type="number"
                id="basic-url"
                // onChange={(e) => props.setlong(e.target.value)}
                onChange={(e) => props.setJob({ ...job, long: e.target.value })}
                aria-describedby="basic-addon3"
                value={job.long}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6} xs={12}>
            {" "}
            <Form.Label htmlFor="basic-url">Job title * </Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                id="basic-url"
                // onChange={(e) => props.setlong(e.target.value)}
                onChange={(e) => props.setJob({ ...job, name: e.target.value })}
                aria-describedby="basic-addon3"
                value={job.name}
              />
            </InputGroup>
          </Col>
          <Col md={6} xs={12}>
            {" "}
            <Form.Label htmlFor="basic-url">
              Number of people to hire for this job *
            </Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                type="number"
                id="basic-url"
                onChange={(e) =>
                  props.setJob({
                    ...job,
                    result_json_format: {
                      ...job.result_json_format,
                      number_of_jobs: e.target.value,
                    },
                  })
                }
                aria-describedby="basic-addon3"
                value={job.result_json_format.number_of_jobs}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6} xs={12}>
            <Form.Label htmlFor="basic-url">
              Which option best describes this job's location? *
            </Form.Label>
            <Dropdown onSelect={handleSelectedLocation}>
              <Dropdown.Toggle variant="success" className="drop_dn">
                {job.result_json_format.job_location_discription ||
                  "Select Location"}
              </Dropdown.Toggle>
              <Dropdown.Menu className="dp_menu">
                <Dropdown.Item eventKey="In-person">In-person</Dropdown.Item>
                <Dropdown.Item eventKey="precise location">
                  Precise location
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col md={6} xs={12}>
            {" "}
            <Form.Label htmlFor="basic-url">Company description</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                as="textarea"
                onChange={(e) =>
                  props.setJob({
                    ...job,
                    result_json_format: {
                      ...job.result_json_format,
                      company_discription: e.target.value,
                    },
                  })
                }
                aria-label="With textarea"
                value={job.result_json_format.company_discription}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <div className="job_bx">
            <h4>Save time posting your job</h4>
            <p>
              Get a head start by generating a job post using Indeed's automated
              solution.
            </p>
            <ul>
              <li>Create a job post quickly and edit from there.</li>
              <li>Optimize your post to find the right people faster.</li>
              <li>Benefit from Indeed's expertise at no additional charge.</li>
            </ul>
            <Form.Check // prettier-ignore
              type="switch"
              id="custom-switch"
              label="Generate a data-driven job post for me."
              onChange={(e) =>
                props.setJob({
                  ...job,
                  result_json_format: {
                    ...job.result_json_format,
                    generate_job_post: e.target.checked, // Use checked instead of value
                  },
                })
              }
              checked={job.result_json_format.generate_job_post} // Make sure this is a boolean
            />
          </div>
        </Row>
        <Row>
          <div className="job_bx">
            <h4>Flexible language requirement</h4>
            <p>
              Indeed is committed to helping refugees from Ukraine and other
              countries get jobs. If the local language is not required for this
              role, please consider including that in your job post.
            </p>
            <Col>
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
                label=" English required"
                // onChange={(e) => props.setResultJsonFormat({...props.result_json_format,is_english_required:e.target.checked})}
                // value={props.job.result_json_format.is_english_required}
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    is_english_required: props.getBoolValue(e),
                  });
                }}
                checked={
                  props.job.result_json_format.is_english_required === "1"
                }
              />
            </Col>
            <Col>
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
                label="French required"
                onChange={(e) =>
                  props.setJob({
                    ...job,
                    result_json_format: {
                      ...job.result_json_format,
                      is_french_required: e.target.checked, // Use checked instead of value
                    },
                  })
                }
                checked={job.result_json_format.is_french_required} // Ensure this is a boolean
              />
            </Col>

            <Col>
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
                label="Is language training provided for employees?"
                value={true}
                onChange={(e) =>
                  props.setJob({
                    ...job,
                    result_json_format: {
                      ...job.result_json_format,
                      is_language_training: e.target.checked, // Use checked instead of value
                    },
                  })
                }
                checked={props.job.result_json_format.is_language_training}
              />
            </Col>
            {/* <Col>
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
                label="Language training provided"
              />
            </Col> */}
          </div>
        </Row>

        <Row>
          <div className="btn_area">
            <p>
              <button className="btm_btn" onClick={props.previousStep}>
                Previous Step
              </button>
            </p>
            <p>
              <button className="btm_btn" onClick={() => props.goToStep(1)}>
                1
              </button>
            </p>
            <p>
              <button className="btm_btn" onClick={() => props.goToStep(2)}>
                2
              </button>
            </p>
            <p>
              <button className="btm_btn" onClick={() => props.goToStep(3)}>
                3
              </button>
            </p>
            <p>
              <button className="btm_btn" onClick={() => props.goToStep(4)}>
                4
              </button>
            </p>
            <p>
              <button
                className="btm_btn"
                onClick={() => {
                  add_job_step_1();
                  // return;
                  props.nextStep();
                  window.scrollTo({ top: 0, behavior: "smooth" });

                }}
              >
                Next Step
              </button>
            </p>
          </div>
        </Row>
      </div>
    </div>
  );
};

const Step2 = (props) => {
  const add_job_step2 = props.add_job_step2;
  const job = props.job;

  return (
    <div>
      <div>
        <div className="wiz_head">
          <h2>Step {props.currentStep}</h2>
          <h3>Add job details</h3>
          <p>Total Steps: {props.totalSteps}</p>
        </div>
        <Row>
          <Col md={6} xs={12}>
            <div className="job_bx">
              {" "}
              <Form.Label htmlFor="basic-url">Job type * </Form.Label>
              <div key={`inline-checkbox`} className="mb-3">
                <Form.Check
                  inline
                  label="Full-time"
                  name="group1"
                  type="checkbox"
                  id={`inline-checkbox-1`}
                  value={true}
                  onChange={(e) => {
                    props.setResultJsonFormat({
                      is_full_time: props.getBoolValue(e),
                    });
                  }}
                  checked={props.job.result_json_format.is_full_time === "1"}
                />
                <Form.Check
                  inline
                  label="Part-time"
                  name="group1"
                  type="checkbox"
                  id={`inline-checkbox-2`}
                  value={true}
                  onChange={(e) => {
                    props.setResultJsonFormat({
                      is_part_time: props.getBoolValue(e),
                    });
                  }}
                  checked={props.job.result_json_format.is_part_time === "1"}
                />
                <Form.Check
                  inline
                  label="Permanent"
                  name="group1"
                  type="checkbox"
                  id={`inline-checkbox-2`}
                  value={true}
                  onChange={(e) => {
                    props.setResultJsonFormat({
                      is_permanent: props.getBoolValue(e),
                    });
                  }}
                  checked={props.job.result_json_format.is_permanent === "1"}
                />
                <Form.Check
                  inline
                  label="Fixed term contract"
                  name="group1"
                  type="checkbox"
                  id={`inline-checkbox-2`}
                  value={true}
                  onChange={(e) => {
                    props.setResultJsonFormat({
                      is_fixed_term_contract: props.getBoolValue(e),
                    });
                  }}
                  checked={
                    props.job.result_json_format.is_fixed_term_contract === "1"
                  }
                />
                <Form.Check
                  inline
                  label="Casual"
                  name="group1"
                  type="checkbox"
                  id={`inline-checkbox-2`}
                  value={true}
                  onChange={(e) => {
                    props.setResultJsonFormat({
                      is_casual: props.getBoolValue(e),
                    });
                  }}
                  checked={props.job.result_json_format.is_casual === "1"}
                />
                <Form.Check
                  inline
                  label="Seasonal"
                  name="group1"
                  type="checkbox"
                  id={`inline-checkbox-2`}
                  value={true}
                  onChange={(e) => {
                    props.setResultJsonFormat({
                      is_seasonal: props.getBoolValue(e),
                    });
                  }}
                  checked={props.job.result_json_format.is_seasonal === "1"}
                />
                <Form.Check
                  inline
                  label="Freelance"
                  name="group1"
                  type="checkbox"
                  id={`inline-checkbox-2`}
                  value={true}
                  onChange={(e) => {
                    props.setResultJsonFormat({
                      is_freelance: props.getBoolValue(e),
                    });
                  }}
                  checked={props.job.result_json_format.is_freelance === "1"}
                />
                <Form.Check
                  inline
                  label="Apprenticeship"
                  name="group1"
                  type="checkbox"
                  id={`inline-checkbox-2`}
                  value={true}
                  onChange={(e) => {
                    props.setResultJsonFormat({
                      is_apprenticeship: props.getBoolValue(e),
                    });
                  }}
                  checked={
                    props.job.result_json_format.is_apprenticeship === "1"
                  }
                />
                <Form.Check
                  inline
                  label="Internship / Co-op"
                  name="group1"
                  type="checkbox"
                  id={`inline-checkbox-2`}
                  value={true}
                  onChange={(e) => {
                    props.setResultJsonFormat({
                      is_internship: props.getBoolValue(e),
                    });
                  }}
                  checked={props.job.result_json_format.is_internship === "1"}
                />
              </div>
            </div>
          </Col>
          <Col md={6} xs={12}>
            {" "}
            <Form.Label htmlFor="basic-url">Expected hours</Form.Label>
            <InputGroup className="mb-3">
            <Form.Control
                id="basic-url"
                onChange={(e) =>
                  props.setJob({
                    ...job,
                    result_json_format: {
                      ...job.result_json_format,
                      expected_hours: e.target.value,
                    },
                  })
                }
                aria-describedby="basic-addon3"
                value={job.result_json_format.expected_hours}
              /> 
              {/* <Form.Control
                id="basic-url"
                onChange={(e) => props.setExpectedHours(e.target.value)}
                value={job.result_json_format.expected_hours}
                aria-describedby="basic-addon3"
              /> */}
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6} xs={12}>
            <div className="job_bx">
              <Form.Label htmlFor="basic-url">Schedule</Form.Label>

              <div key={`inline-checkbox`} className="mb-3">
                <Form.Check
                  inline
                  label="Monday to Friday"
                  name="group1"
                  type="checkbox"
                  id={`inline-checkbox-1`}
                  value={true}
                  onChange={(e) => {
                    props.setResultJsonFormat({
                      is_monday_to_friday: props.getBoolValue(e),
                    });
                  }}
                  checked={
                    props.job.result_json_format.is_monday_to_friday === "1"
                  }
                />
                <Form.Check
                  inline
                  label="Weekends as needed"
                  name="group1"
                  type="checkbox"
                  id={`inline-checkbox-2`}
                  value={true}
                  onChange={(e) => {
                    props.setResultJsonFormat({
                      is_weekends_only: props.getBoolValue(e),
                    });
                  }}
                  checked={
                    props.job.result_json_format.is_weekends_only === "1"
                  }
                />
                <Form.Check
                  inline
                  label="8 hour shift"
                  name="group1"
                  type="checkbox"
                  id={`inline-checkbox-2`}
                  value={true}
                  onChange={(e) => {
                    props.setResultJsonFormat({
                      is8hour_shift: props.getBoolValue(e),
                    });
                  }}
                  checked={props.job.result_json_format.is8hour_shift === "1"}
                />
                <Form.Check
                  inline
                  label="Day shift"
                  name="group1"
                  type="checkbox"
                  id={`inline-checkbox-2`}
                  value={true}
                  onChange={(e) => {
                    props.setResultJsonFormat({
                      is_day_shift: props.getBoolValue(e),
                    });
                  }}
                  checked={props.job.result_json_format.is_day_shift === "1"}
                />
                <Form.Check
                  inline
                  label="Evening shift"
                  name="group1"
                  type="checkbox"
                  id={`inline-checkbox-2`}
                  value={true}
                  onChange={(e) => {
                    props.setResultJsonFormat({
                      is_evening_shift: props.getBoolValue(e),
                    });
                  }}
                  checked={
                    props.job.result_json_format.is_evening_shift === "1"
                  }
                />
                <Form.Check
                  inline
                  label="Night shift"
                  name="group1"
                  type="checkbox"
                  id={`inline-checkbox-2`}
                  value={true}
                  onChange={(e) => {
                    props.setResultJsonFormat({
                      is_night_shift: props.getBoolValue(e),
                    });
                  }}
                  checked={props.job.result_json_format.is_night_shift === "1"}
                />
                <Form.Check
                  inline
                  label="Morning shift"
                  name="group1"
                  type="checkbox"
                  id={`inline-checkbox-2`}
                  value={true}
                  onChange={(e) => {
                    props.setResultJsonFormat({
                      is_morning_shift: props.getBoolValue(e),
                    });
                  }}
                  checked={
                    props.job.result_json_format.is_morning_shift === "1"
                  }
                />
                <Form.Check
                  inline
                  label="Overtime"
                  name="group1"
                  type="checkbox"
                  id={`inline-checkbox-2`}
                  value={true}
                  onChange={(e) => {
                    props.setResultJsonFormat({
                      is_overtime: props.getBoolValue(e),
                    });
                  }}
                  checked={props.job.result_json_format.is_overtime === "1"}
                />
                <Form.Check
                  inline
                  label="On call"
                  name="group1"
                  type="checkbox"
                  id={`inline-checkbox-2`}
                  value={true}
                  onChange={(e) => {
                    props.setResultJsonFormat({
                      is_on_call: props.getBoolValue(e),
                    });
                  }}
                  checked={props.job.result_json_format.is_on_call === "1"}
                />
                <Form.Check
                  inline
                  label="No weekends"
                  name="group1"
                  type="checkbox"
                  id={`inline-checkbox-2`}
                  value={true}
                  onChange={(e) => {
                    props.setResultJsonFormat({
                      is_no_weekends: props.getBoolValue(e),
                    });
                  }}
                  checked={props.job.result_json_format.is_no_weekends === "1"}
                />
                <Form.Check
                  inline
                  label="Every Weekend"
                  name="group1"
                  type="checkbox"
                  id={`inline-checkbox-2`}
                  value={true}
                  onChange={(e) => {
                    props.setResultJsonFormat({
                      is_weekends_only: props.getBoolValue(e),
                    });
                  }}
                  checked={
                    props.job.result_json_format.is_weekends_only === "1"
                  }
                />
                <Form.Check
                  inline
                  label="Holidays"
                  name="group1"
                  type="checkbox"
                  id={`inline-checkbox-2`}
                  value={true}
                  onChange={(e) => {
                    props.setResultJsonFormat({
                      is_holiday: props.getBoolValue(e),
                    });
                  }}
                  checked={props.job.result_json_format.is_holiday === "1"}
                />
                <Form.Check
                  inline
                  label="10 hour shift"
                  name="group1"
                  type="checkbox"
                  id={`inline-checkbox-2`}
                  value={true}
                  onChange={(e) => {
                    props.setResultJsonFormat({
                      is10hour_shift: props.getBoolValue(e),
                    });
                  }}
                  checked={props.job.result_json_format.is10hour_shift === "1"}
                />
                <Form.Check
                  inline
                  label="12 hour shift"
                  name="group1"
                  type="checkbox"
                  id={`inline-checkbox-2`}
                  value={true}
                  onChange={(e) => {
                    props.setResultJsonFormat({
                      is12hour_Shift: props.getBoolValue(e),
                    });
                  }}
                  checked={props.job.result_json_format.is12hour_Shift === "1"}
                />
                <Form.Check
                  inline
                  label="4 hour shift"
                  name="group1"
                  type="checkbox"
                  id={`inline-checkbox-2`}
                  value={true}
                  onChange={(e) => {
                    props.setResultJsonFormat({
                      is4hour_shift: props.getBoolValue(e),
                    });
                  }}
                  checked={props.job.result_json_format.is4hour_shift === "1"}
                />
                <Form.Check
                  inline
                  label="Weekends only"
                  name="group1"
                  type="checkbox"
                  id={`inline-checkbox-2`}
                  value={true}
                  onChange={(e) => {
                    props.setResultJsonFormat({
                      is_weekends_only: props.getBoolValue(e),
                    });
                  }}
                  checked={
                    props.job.result_json_format.is_weekends_only === "1"
                  }
                />
                <Form.Check
                  inline
                  label="Other"
                  name="group1"
                  type="checkbox"
                  id={`inline-checkbox-2`}
                  value={true}
                  onChange={(e) => {
                    props.setResultJsonFormat({
                      is_other_shift: props.getBoolValue(e),
                    });
                  }}
                  checked={props.job.result_json_format.is_other_shift === "1"}
                />
              </div>
            </div>
          </Col>
          <Col md={6} xs={12}>
            {" "}
            <Form.Label htmlFor="basic-url">
              How long is the contract?
            </Form.Label>
            <InputGroup className="mb-3">
            <Form.Control
                id="basic-url"
                onChange={(e) =>
                  props.setJob({
                    ...job,
                    result_json_format: {
                      ...job.result_json_format,
                      contract_lenght: e.target.value,
                    },
                  })
                }
                aria-describedby="basic-addon3"
                value={job.result_json_format.contract_lenght}
              /> 
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6} xs={12}>
            <div className="mb-3">
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
                label=" Is there a planned start date for this job?"
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    is_planned_start_date: props.getBoolValue(e),
                  });
                }}
                checked={
                  props.job.result_json_format.is_planned_start_date === "1"
                }
              />
            </div>
          </Col>
          <Col md={6} xs={12}></Col>
        </Row>

        <Row>
          <div className="btn_area">
            <p>
              <button className="btm_btn" onClick={props.previousStep}>
                Previous Step
              </button>
            </p>
            <p>
              <button className="btm_btn" onClick={() => props.goToStep(1)}>
                1
              </button>
            </p>
            <p>
              <button className="btm_btn" onClick={() => props.goToStep(2)}>
                2
              </button>
            </p>
            <p>
              <button className="btm_btn" onClick={() => props.goToStep(3)}>
                3
              </button>
            </p>
            <p>
              <button className="btm_btn" onClick={() => props.goToStep(4)}>
                4
              </button>
            </p>
            <p>
              <button
                className="btm_btn"
                onClick={() => {
                  add_job_step2();
                  props.nextStep();
                  window.scrollTo({ top: 0, behavior: "smooth" });

                }}
              >
                Next Step
              </button>
            </p>
          </div>
        </Row>
      </div>
    </div>
  );
};
const Step3 = (props) => {
  const add_job_step3 = props.add_job_step3;
  const job = props.job;

  return (
    <div>
      <div>
        <div className="wiz_head">
          <h2>Step {props.currentStep}</h2>
          <h3>Add pay and benefits</h3>
          <p>Total Steps: {props.totalSteps}</p>
        </div>
        <Row>
          <h4>Pay</h4>
          <p>
            Review the pay we estimated for your job and adjust it as needed.
            Check your local minimum wage.
          </p>
          <h5>Show pay by Range</h5>

          <Col md={4} xs={12}>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">MinimumCA$ </InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="$0.00"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={(e) =>
                  props.setJob({
                    ...job,
                    result_json_format: {
                      ...job.result_json_format,
                      minimum_ca: e.target.value,
                    },
                  })
                }
                value={job.result_json_format.minimum_ca}
              />
            </InputGroup>
          </Col>
          <Col md={1} xs={12}>
            {" "}
            {/* <Form.Label htmlFor="basic-url">-</Form.Label> */}
            TO
          </Col>
          <Col md={4} xs={12}>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">MaximumCA$ </InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="$0.00"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={(e) =>
                  props.setJob({
                    ...job,
                    result_json_format: {
                      ...job.result_json_format,
                      maximum_ca: e.target.value,
                    },
                  })
                }
                value={job.result_json_format.maximum_ca}
              />
            </InputGroup>
          </Col>
          <Col md={3} xs={12}>
            {" "}
            {/* <Form.Label htmlFor="basic-url">-</Form.Label> */}
            Rate per hour
          </Col>
        </Row>
        <Row>
          <h5>Show by Fixed hours</h5>

          <Col md={4} xs={12}>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">Fixed at </InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="$0.00"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={(e) =>
                  props.setJob({
                    ...job,
                    result_json_format: {
                      ...job.result_json_format,
                      fixed_at_hours: e.target.value,
                    },
                  })
                }
                value={job.result_json_format.fixed_at_hours}
              />
            </InputGroup>
          </Col>
          <Col md={3} xs={12}>
            {" "}
            {/* <Form.Label htmlFor="basic-url">-</Form.Label> */}
            Hours per week
          </Col>
        </Row>
        <Row>
          <div className="job_bx">
            <Col>
              <h5>Supplemental Pay</h5>

              <div key={`inline-checkbox`} className="mb-3">
                <Form.Check
                  inline
                  label="Overtime pay"
                  name="group1"
                  type="checkbox"
                  id={`inline-checkbox-1`}
                  value={true}
                  onChange={(e) => {
                    props.setResultJsonFormat({
                      is_over_time_pay: props.getBoolValue(e),
                    });
                  }}
                  checked={
                    props.job.result_json_format.is_over_time_pay === "1"
                  }
                />
                <Form.Check
                  inline
                  label="Bonus pay"
                  name="group1"
                  type="checkbox"
                  id={`inline-checkbox-2`}
                  value={true}
                  onChange={(e) => {
                    props.setResultJsonFormat({
                      is_bonus_pay: props.getBoolValue(e),
                    });
                  }}
                  checked={props.job.result_json_format.is_bonus_pay === "1"}
                />
                <Form.Check
                  inline
                  label="Tips"
                  name="group1"
                  type="checkbox"
                  id={`inline-checkbox-2`}
                  value={true}
                  onChange={(e) => {
                    props.setResultJsonFormat({
                      is_tips: props.getBoolValue(e),
                    });
                  }}
                  checked={props.job.result_json_format.is_tips === "1"}
                />
                <Form.Check
                  inline
                  label="Commission pay"
                  name="group1"
                  type="checkbox"
                  id={`inline-checkbox-2`}
                  value={true}
                  onChange={(e) => {
                    props.setResultJsonFormat({
                      is_commission_pay: props.getBoolValue(e),
                    });
                  }}
                  checked={
                    props.job.result_json_format.is_commission_pay === "1"
                  }
                />
                <Form.Check
                  inline
                  label="Signing bonus"
                  name="group1"
                  type="checkbox"
                  id={`inline-checkbox-2`}
                  value={true}
                  onChange={(e) => {
                    props.setResultJsonFormat({
                      is_signing_bonus: props.getBoolValue(e),
                    });
                  }}
                  checked={
                    props.job.result_json_format.is_signing_bonus === "1"
                  }
                />
                <Form.Check
                  inline
                  label="Retention bonus"
                  name="group1"
                  type="checkbox"
                  id={`inline-checkbox-2`}
                  value={true}
                  onChange={(e) => {
                    props.setResultJsonFormat({
                      is_retention_bonus: props.getBoolValue(e),
                    });
                  }}
                  checked={
                    props.job.result_json_format.is_retention_bonus === "1"
                  }
                />
                <Form.Check
                  inline
                  label="Other"
                  name="group1"
                  type="checkbox"
                  id={`inline-checkbox-2`}
                  value={true}
                  onChange={(e) => {
                    props.setResultJsonFormat({
                      is_other_bonus: props.getBoolValue(e),
                    });
                  }}
                  checked={props.job.result_json_format.is_other_bonus === "1"}
                />
              </div>
            </Col>
          </div>
        </Row>
        <Row>
          <div className="job_bx">
            <h4>Benefits</h4>
            <h5>Tip: Use competitive benefits</h5>
            <p>
              We've selected competitive benefits for Labourer jobs in Mount
              Uniacke, NS B0N 1Z0. The higher the percentage, the more jobs have
              these benefits. Simply remove those you don’t offer.
            </p>
            <Col>
              {" "}
              <Form.Check
                inline
                label="Dental care (17%)"
                name="group1"
                type="checkbox"
                id={`inline-checkbox-2`}
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    is_dental_care: props.getBoolValue(e),
                  });
                }}
                checked={props.job.result_json_format.is_dental_care === "1"}
              />
              <Form.Check
                inline
                label="Extended health care (15%)"
                name="group1"
                type="checkbox"
                id={`inline-checkbox-2`}
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    is_extended_health_care: props.getBoolValue(e),
                  });
                }}
                checked={
                  props.job.result_json_format.is_extended_health_care === "1"
                }
              />
              <Form.Check
                inline
                label="Vision care (10%)"
                name="group1"
                type="checkbox"
                id={`inline-checkbox-2`}
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    is_vision_care: props.getBoolValue(e),
                  });
                }}
                checked={props.job.result_json_format.is_vision_care === "1"}
              />
            </Col>
          </div>
        </Row>
        <Row>
          <div className="job_bx">
            <h5>Are any of the following benefits also offered?</h5>

            <Col>
              {" "}
              <Form.Check
                inline
                label="Life insurance"
                name="group1"
                type="checkbox"
                id={`inline-checkbox-2`}
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    is_life_insurance: props.getBoolValue(e),
                  });
                }}
                checked={props.job.result_json_format.is_life_insurance === "1"}
              />
              <Form.Check
                inline
                label="RRSP match"
                name="group1"
                type="checkbox"
                id={`inline-checkbox-2`}
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    is_rrsp_match: props.getBoolValue(e),
                  });
                }}
                checked={props.job.result_json_format.is_rrsp_match === "1"}
              />
              <Form.Check
                inline
                label="Paid time off"
                name="group1"
                type="checkbox"
                id={`inline-checkbox-2`}
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    is_paid_time_off: props.getBoolValue(e),
                  });
                }}
                checked={props.job.result_json_format.is_paid_time_off === "1"}
              />
              <Form.Check
                inline
                label="On-site parking"
                name="group1"
                type="checkbox"
                id={`inline-checkbox-2`}
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    is_on_site_parking: props.getBoolValue(e),
                  });
                }}
                checked={
                  props.job.result_json_format.is_on_site_parking === "1"
                }
              />
              <Form.Check
                inline
                label="Employee assistance program"
                name="group1"
                type="checkbox"
                id={`inline-checkbox-2`}
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    is_employee_assistance_program: props.getBoolValue(e),
                  });
                }}
                checked={
                  props.job.result_json_format
                    .is_employee_assistance_program === "1"
                }
              />
              <Form.Check
                inline
                label="Disability insurance"
                name="group1"
                type="checkbox"
                id={`inline-checkbox-2`}
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    is_disability_insurance: props.getBoolValue(e),
                  });
                }}
                checked={
                  props.job.result_json_format.is_disability_insurance === "1"
                }
              />
              <Form.Check
                inline
                label="Casual dress"
                name="group1"
                type="checkbox"
                id={`inline-checkbox-2`}
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    is_casual_dress: props.getBoolValue(e),
                  });
                }}
                checked={props.job.result_json_format.is_casual_dress === "1"}
              />
              <Form.Check
                inline
                label="Company events"
                name="group1"
                type="checkbox"
                id={`inline-checkbox-2`}
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    is_company_events: props.getBoolValue(e),
                  });
                }}
                checked={props.job.result_json_format.is_company_events === "1"}
              />
              <Form.Check
                inline
                label="Store discount"
                name="group1"
                type="checkbox"
                id={`inline-checkbox-2`}
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    is_store_discount: props.getBoolValue(e),
                  });
                }}
                checked={props.job.result_json_format.is_store_discount === "1"}
              />
              <Form.Check
                inline
                label="Company pension"
                name="group1"
                type="checkbox"
                id={`inline-checkbox-2`}
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    is_company_pension: props.getBoolValue(e),
                  });
                }}
                checked={
                  props.job.result_json_format.is_company_pension === "1"
                }
              />
              <Form.Check
                inline
                label="Tuition reimbursement"
                name="group1"
                type="checkbox"
                id={`inline-checkbox-2`}
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    is_tuition_reimbursement: props.getBoolValue(e),
                  });
                }}
                checked={
                  props.job.result_json_format.is_tuition_reimbursement === "1"
                }
              />
              <Form.Check
                inline
                label="Wellness program"
                name="group1"
                type="checkbox"
                id={`inline-checkbox-2`}
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    is_wellness_program: props.getBoolValue(e),
                  });
                }}
                checked={
                  props.job.result_json_format.is_wellness_program === "1"
                }
              />
              <Form.Check
                inline
                label="Discounted or free food"
                name="group1"
                type="checkbox"
                id={`inline-checkbox-2`}
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    is_discounted_or_freefood: props.getBoolValue(e),
                  });
                }}
                checked={
                  props.job.result_json_format.is_discounted_or_freefood === "1"
                }
              />
              <Form.Check
                inline
                label="Work from home"
                name="group1"
                type="checkbox"
                id={`inline-checkbox-2`}
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    is_work_from_home: props.getBoolValue(e),
                  });
                }}
                checked={props.job.result_json_format.is_work_from_home === "1"}
              />
              <Form.Check
                inline
                label="Profit sharing"
                name="group1"
                type="checkbox"
                id={`inline-checkbox-2`}
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    is_profit_sharing: props.getBoolValue(e),
                  });
                }}
                checked={props.job.result_json_format.is_profit_sharing === "1"}
              />
              <Form.Check
                inline
                label="On-site gym"
                name="group1"
                type="checkbox"
                id={`inline-checkbox-2`}
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    is_on_site_gym: props.getBoolValue(e),
                  });
                }}
                checked={props.job.result_json_format.is_on_site_gym === "1"}
              />
              <Form.Check
                inline
                label="Employee stock purchase plan"
                name="group1"
                type="checkbox"
                id={`inline-checkbox-2`}
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    is_employee_stock_purchase_plan: props.getBoolValue(e),
                  });
                }}
                checked={
                  props.job.result_json_format
                    .is_employee_stock_purchase_plan === "1"
                }
              />
              <Form.Check
                inline
                label="Relocation assistance"
                name="group1"
                type="checkbox"
                id={`inline-checkbox-2`}
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    is_relocation_assistance: props.getBoolValue(e),
                  });
                }}
                checked={
                  props.job.result_json_format.is_relocation_assistance === "1"
                }
              />
              <Form.Check
                inline
                label="Company car"
                name="group1"
                type="checkbox"
                id={`inline-checkbox-2`}
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    is_company_car: props.getBoolValue(e),
                  });
                }}
                checked={props.job.result_json_format.is_company_car === "1"}
              />
              <Form.Check
                inline
                label="Stock options"
                name="group1"
                type="checkbox"
                id={`inline-checkbox-2`}
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    isStockOptions: props.getBoolValue(e),
                  });
                }}
                checked={props.job.result_json_format.isStockOptions === "1"}
              />
              <Form.Check
                inline
                label="Commuter benefits"
                name="group1"
                type="checkbox"
                id={`inline-checkbox-2`}
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    is_commuter_benefits: props.getBoolValue(e),
                  });
                }}
                checked={
                  props.job.result_json_format.is_commuter_benefits === "1"
                }
              />
              <Form.Check
                inline
                label="Housing allowance"
                name="group1"
                type="checkbox"
                id={`inline-checkbox-2`}
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    is_housing_allowance: props.getBoolValue(e),
                  });
                }}
                checked={
                  props.job.result_json_format.is_housing_allowance === "1"
                }
              />
              <Form.Check
                inline
                label="Automobile allowance"
                name="group1"
                type="checkbox"
                id={`inline-checkbox-2`}
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    is_automobile_allowance: props.getBoolValue(e),
                  });
                }}
                checked={
                  props.job.result_json_format.is_automobile_allowance === "1"
                }
              />
              <Form.Check
                inline
                label="Language training provided"
                name="group1"
                type="checkbox"
                id={`inline-checkbox-2`}
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    is_language_training: props.getBoolValue(e),
                  });
                }}
                checked={
                  props.job.result_json_format.is_language_training === "1"
                }
              />
              <Form.Check
                inline
                label="On-site childcare"
                name="group1"
                type="checkbox"
                id={`inline-checkbox-2`}
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    is_on_site_childcare: props.getBoolValue(e),
                  });
                }}
                checked={
                  props.job.result_json_format.is_on_site_childcare === "1"
                }
              />
              <Form.Check
                inline
                label="VRSP"
                name="group1"
                type="checkbox"
                id={`inline-checkbox-2`}
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    is_vrsp: props.getBoolValue(e),
                  });
                }}
                checked={props.job.result_json_format.is_vrsp === "1"}
              />
              <Form.Check
                inline
                label="Flexible schedule"
                name="group1"
                type="checkbox"
                id={`inline-checkbox-2`}
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    is_flexible_schedule: props.getBoolValue(e),
                  });
                }}
                checked={
                  props.job.result_json_format.is_flexible_schedule === "1"
                }
              />
              <Form.Check
                inline
                label="Other"
                name="group1"
                type="checkbox"
                id={`inline-checkbox-2`}
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    is_other_benefits: props.getBoolValue(e),
                  });
                }}
                checked={props.job.result_json_format.is_other_benefits === "1"}
              />
            </Col>
          </div>
        </Row>

        <Row>
          <div className="btn_area">
            <p>
              <button className="btm_btn" onClick={props.previousStep}>
                Previous Step
              </button>
            </p>
            <p>
              <button className="btm_btn" onClick={() => props.goToStep(1)}>
                1
              </button>
            </p>
            <p>
              <button className="btm_btn" onClick={() => props.goToStep(2)}>
                2
              </button>
            </p>
            <p>
              <button className="btm_btn" onClick={() => props.goToStep(3)}>
                3
              </button>
            </p>
            <p>
              <button className="btm_btn" onClick={() => props.goToStep(4)}>
                4
              </button>
            </p>
            <p>
              <button
                className="btm_btn"
                onClick={() => {
                 
                  add_job_step3();
                   props.nextStep();
                   window.scrollTo({ top: 0, behavior: "smooth" });

                }}
              >
                Next Step
              </button>
            </p>
          </div>
        </Row>
      </div>
    </div>
  );
};

const Step4 = (props) => {
  const navigate = useNavigate();
  const job = props.job;

  const navigateToPath = (path) => {
    navigate(path);
  };
  const add_job_step4 = props.add_job_step4;
  return (
    <div>
      <div>
        <div className="wiz_head">
          <h2>Step {props.currentStep}</h2>
          <h3>Set preferences</h3>
          <p>Total Steps: {props.totalSteps}</p>
        </div>
        <Row>
          <h4>Communication preferences</h4>
          <p>Send daily updates to *</p>
        </Row>

        <Row>
          <div className="job_bx">
            <h5>Add email</h5>

            <Col>
              <Form.Check
                inline
                label=" Plus, send an individual email update each time someone applies."
                name="group1"
                type="checkbox"
                id={`inline-checkbox-1`}
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    email_update: props.getBoolValue(e),
                  });
                }}
                checked={props.job.result_json_format.email_update === "1"}
              />
            </Col>

            <p>Let potential candidates contact you about this job</p>
            <Col>
              <Form.Check
                inline
                label="  By email to the address provided"
                name="group1"
                type="checkbox"
                id={`inline-checkbox-1`}
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    email: props.getBoolValue(e),
                  });
                }}
                checked={props.job.result_json_format.email === "1"}
              />
            </Col>
            <Col>
              <Form.Check
                inline
                label="  By phone"
                name="group1"
                type="checkbox"
                id={`inline-checkbox-1`}
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    by_phone: props.getBoolValue(e),
                  });
                }}
                checked={props.job.result_json_format.by_phone === "1"}
              />
            </Col>
          </div>
        </Row>

        <Row>
          <div className="job_bx">
            <h5>Application preferences</h5>

            <Col md={12} sm={12}>
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
                label=" Ask potential candidates for a resume?Give the option to include a resume"
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    is_resume: props.getBoolValue(e),
                  });
                }}
                checked={props.job.result_json_format.is_resume === "1"}
              />
            </Col>

            <Col md={12} sm={12}>
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
                label=" Is there an application deadline? "
                value={true}
                onChange={(e) => {
                  props.setResultJsonFormat({
                    is_application_deadline: props.getBoolValue(e),
                  });
                }}
                checked={
                  props.job.result_json_format.is_application_deadline === "1"
                }
              />
            </Col>
          </div>
        </Row>
        <Row>
          <h5>Hiring settings</h5>

          <Col md={6} xs={12}>
            <Form.Label htmlFor="basic-url">
              Hiring timeline for this job *
            </Form.Label>
            <InputGroup className="mb-3">
            <Form.Control
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={(e) =>
                  props.setJob({
                    ...job,
                    result_json_format: {
                      ...job.result_json_format,
                      hiring_timeline: e.target.value,
                    },
                  })
                }
                value={job.result_json_format.hiring_timeline}
              />
            </InputGroup>
          </Col>
        </Row>

        <Row>
          <div className="btn_area">
            <p>
              <button className="btm_btn" onClick={props.previousStep}>
                Previous Step
              </button>
            </p>
            <p>
              <button className="btm_btn" onClick={() => props.goToStep(1)}>
                1
              </button>
            </p>
            <p>
              <button className="btm_btn" onClick={() => props.goToStep(2)}>
                2
              </button>
            </p>
            <p>
              <button className="btm_btn" onClick={() => props.goToStep(3)}>
                3
              </button>
            </p>
            <p>
              <button className="btm_btn" onClick={() => props.goToStep(4)}>
                4
              </button>
            </p>
            <p>
              <button
                className="btm_btn"
                onClick={() => {
                  // props.nextStep();
                  add_job_step4();
                  navigateToPath("/");
                  window.scrollTo({ top: 0, behavior: "smooth" });

                }}
              >
                Next Step
              </button>
            </p>
          </div>
        </Row>
      </div>
    </div>
  );
};
