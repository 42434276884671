import React, { useEffect, useContext } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./../styles/Services_payment.css";
import "./../styles/categorydetaillisting.css";
import Carousel from "react-bootstrap/Carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Alert from "react-bootstrap/Alert";

import {
  faAnglesLeft,
  faBookmark,
  faBookBookmark,
  faCheck,
  faCircleCheck,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import InputGroup from "react-bootstrap/InputGroup";
import Nav_bar_area from "./NavBar";
import { useNavigate, useLocation } from "react-router-dom";
import { Constant } from "../common/Constants";
import { ContextApiContext } from "../context/ContextApi";
import Common, {
  googleTranslate,
  SendRequest,
  SendRequestContetType,
} from "../common/Common";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Map from "./Components/Map";
import Pagination from "./Components/Pagination";
import Footer from "./Components/Footer";
import { prefix } from "@fortawesome/free-brands-svg-icons";

export default function CategoryDetailListing() {
  const [detailsList, setDetailsList] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [community, setCommunity] = useState([]);
  const [limit, setLimit] = useState("20");
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const [languageFilter, setLanguageFilter] = useState("Language");
  const [communityFilter, setCommunityFilter] = useState("Community");
  const [categoryResult, setCategoryResult] = useState([]);
  const [bookmark, setBookmark] = useState([]);
  const location = useLocation();

  const { contextState, updateContextState } = useContext(ContextApiContext);

  const navigate = useNavigate();

  const navigateToPath = (path, props) => {
    navigate(path, { state: props });
  };

  const handleTabClick = (index) => {
    // setActiveTab(index);
  };

  useEffect(() => {
    console.log("init search");
    initVariables();
  }, []);

  useEffect(() => {
    console.log('CategoryResult updated:', categoryResult);
}, [categoryResult]);

  const initVariables = () => {
    getLanguages();
    getCommunity();
    getCategoryResultList();
  };

  useEffect(() => {
    console.log("context changed");
    if (contextState.search.reload) {
      getCategoryResultList();
    }
  }, [contextState.search.reload]);

  const getCategoryResultList = async (page) => {
    try {
      page = page || 1;
      console.log("category detail; result");
      let serach_filter = contextState.search;
      let user = contextState.user;

      console.log("search_obj", serach_filter);
      updateContextState({ reload: false }, "search");

      let get_search_results_url = `${Constant.get_category_result}?page=${page}&limit=${limit}`;

      const res = await SendRequestContetType(
        "post",
        get_search_results_url,
        JSON.stringify(serach_filter),
        contextState.user.is_loggedin
      );

      if (res.status) {
        let response = res.response;
        setCategoryResult(response.data);
        setCurrentPage(response.current_page);
        setLastPage(response.last_page);

        console.log("get response ", response);
      } else {
        updateContextState("Empty Results", "error_msg");
      }
    } catch (error) {
      updateContextState("List is empty", "error_msg");
      console.error("Error during login:", error);
    }
  };

  const getLanguages = async () => {
    try {
      const response = await fetch(`${Constant.get_language}`);
      const data = await response.json();
      setLanguages(data.response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getCommunity = async () => {
    try {
      const response = await fetch(`${Constant.get_community}`);
      const data = await response.json();
      console.log(data);
      setCommunity(data.response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const CommunityHandler = (searchText) => {
    setCommunityFilter(searchText);
    updateContextState(
      {
        community: searchText,
        reload: true,
      },
      "search"
    );
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const LanguageHandler = (searchText) => {
    setLanguageFilter(searchText);
    updateContextState(
      {
        language: searchText,
        reload: true,
      },
      "search"
    );
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleShowMoreClick = (result) => {
    navigateToPath("/community_people", { result });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const add_remove_bookmark = async (
    resultId,
    is_already_bookmarked,
    index
  ) => {
    try {
      console.log("Result ID:", resultId);
      console.log("index ID:", index);
      console.log("is_already_bookmarked ID:", is_already_bookmarked);

      // Prepare the request payload as JSON
      const payload = JSON.stringify({ category_result_id: resultId });

      // Define the API URL
      const getSearchResultsUrl = `${Constant.add_remove_bookmark}`;

      // Make the POST request with JSON payload
      const res = await SendRequestContetType(
        "POST",
        getSearchResultsUrl,
        payload, // Use JSON payload
        true
      );

      console.log("Response:", res);
      if (res.status) {
        console.log("Bookmark added successfully:", res.response);
        let user_obj = res.response;
        const updatedBookmark = is_already_bookmarked ? true : null;

        const updatedCategoryResult = categoryResult.map((item, idx) => {
            if (idx === index) {
              console.log('found inex',idx);
                return {
                    ...item,
                    category_result_bookmark: updatedBookmark,
                };
            }
            return item;
        });

  setCategoryResult((prevState) => {
    console.log('Previous state:', prevState); // Log previous state
    console.log('Updated state:', updatedCategoryResult); // Log updated state
    return updatedCategoryResult; // Return the new state
  });
        // setCategoryResult(updatedCategoryResult);


      } else {
        console.log("Error:", res);
        updateContextState(res.error?.message[0], "error_msg");
      }
    } catch (error) {
      console.error("Error adding bookmark:", error);
    }
  };

  return (
    <div>
      <Nav_bar_area className="colooo" />
      <div className="dggg">
        <Row className="md-4">
          <Col xs={12} md={12}>
            <div className="hvbj">
              <h1>
                Results{" "}
                {contextState.search.text
                  ? " : " + contextState.search.text
                  : ""}
              </h1>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Dropdown
              align="end"
              className="drp_dn"
              onSelect={(e) => LanguageHandler(e)}
            >
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="dp_bnt"
                eventKey={""}
              >
                {languageFilter ? languageFilter : "Language"}
              </Dropdown.Toggle>

              <Dropdown.Menu className="dp_menu">
                <Dropdown.Item key={"aki"} eventKey="" className="dp_items">
                  Language
                </Dropdown.Item>

                {languages.map((language) => (
                  <Dropdown.Item
                    key={language.id}
                    eventKey={language.language}
                    className="dp_items"
                  >
                    {language.language}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col>
            <Dropdown onSelect={(e) => CommunityHandler(e)} className="drp_dn">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {communityFilter ? communityFilter : "Community"}
              </Dropdown.Toggle>

              <Dropdown.Menu className="dp_menu">
                <Dropdown.Item key={"ak"} eventKey="" className="dp_items">
                  Community
                </Dropdown.Item>
                {community.map((community) => (
                  <Dropdown.Item
                    key={community.id}
                    eventKey={community.community}
                    className="dp_items"
                  >
                    {community.community}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row>
          {categoryResult.map((result,index) => (
            <Col xs={12} md={6} key={result.id}>
              <div className="box">
                <Row>
                  <Col xs={12} md={9}>
                    <div
                      onClick={() => handleShowMoreClick(result, index)}
                      className="inner-box1"
                    >
                      <h2>{result.name}</h2>
                      <h6>{result.category_name}</h6>
                      <h6>
                        {result?.distance?.toFixed(2) ?? ""}{" "}
                        {result?.distance ? "KM" : ""}
                      </h6>
                      <h6>{result.address}</h6>
                      <h6>{result.taxonomy ?? ""}</h6>
                      <div className="show_area">
                        <Button variant="" className="bt show_btn">
                          Show More
                        </Button>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} md={3}>
                    <div className="inner-box2">
                      <img
                        className="logo_ft"
                        src="/images/slicing/Help101-Web.png"
                        alt="Content"
                      />
                     <Button
  className="fav"
  onClick={() =>
    add_remove_bookmark(
      result.id,
      result.category_result_bookmark === null,
      index
    )
  }
>
  {result.category_result_bookmark === null ? "Bookmark  " : "Saved  "}{" "}
  <FontAwesomeIcon
    icon={
      result.category_result_bookmark === null
        ? faBookmark
        : faCircleCheck
    }
  />
</Button>

                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          ))}
        </Row>
        <Pagination
          currentPage={currentPage}
          lastPage={lastPage}
          getCategoryResultList={getCategoryResultList}
        />
        <Row>
          <Map />
        </Row>
      </div>
      <Footer />
    </div>
  );
}
