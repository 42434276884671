import React, { createContext, useState } from "react";
import { Constant } from "../common/Constants";

export const ContextApiContext = React.createContext();

// let guest_user = {
//     id: "0",
//     name: "Guest",
//     access_token: "Basic ZGVtYXMtYXBwLW1vYmlsZTtaR1Z0WVhNdFlYQndMVzF2WW1sc1pRPT0=",
//     role_id: 2,
//     rememberme:false,
//     is_loggedin:false,
// };
let initState = {
  avalible_languages: [
    {
      id: "1",
      name: "English",
      prefix: "_en",
    },
    {
      id: "2",
      name: "Russian",
      prefix: "_ru",
    },
  ],
  language: {
    id: 1,
    name: "English",
    prefix: "_en",
  },
  search: {
        category_id: 0,
        text: "",
        lookingfor:"",
        lat: 43.6529837,
        long: -79.3854778,
        language: "",
        community: "",
        reload:false
  },
  // "language":{
  //     "id":'2',
  //     "name":"Russian",
  //     "prefix":"_ru"
  // },
  show_login_modal: false,
  show_error: false,
  error_msg: "",
  user:
    localStorage.getItem("user") === null
      ? Constant.guest_user
      : JSON.parse(localStorage.getItem("user")),
  // "user": {
  //     id: "0",
  //     name: "Guest",
  //     access_token: "Basic cmVlbHNwcm8tYXBwLW1vYmlsZTogY21WbGJITndjbTh0WVhCd0xXMXZZbWxzWlE9PQ==",
  //     role_id: 2,
  // },
};

export const ContexApiProvider = (props) => {
  const [contextState, setContextState] = useState(initState);

  const updateContextState = (update_obj, obj_name) => {
    let objContextState = contextState;
    switch (obj_name) {
      case "language":
        let lang = contextState["avalible_languages"].find((element) => {
          return element.id === update_obj;
        });
        objContextState[obj_name] = lang;
        setContextState({ ...contextState, objContextState });
        break;
      case "update_user":
        objContextState["user"] = update_obj;
        if (update_obj.id) {
          update_obj.is_loggedin = true;
        } else {
          update_obj.is_loggedin = false;
        }
        localStorage.setItem("user", JSON.stringify(update_obj));
        setContextState({ ...contextState, objContextState });
        break;
      case "logout_user":
        objContextState["user"] = Constant.guest_user;
        localStorage.setItem("user", JSON.stringify(Constant.guest_user));
        setContextState({ ...contextState, objContextState });
        break;

      default:
      setContextState((prevState) => {
      if (typeof update_obj === 'object' && update_obj !== null) {
        return {
          ...prevState,
          [obj_name]: { ...prevState[obj_name], ...update_obj },
        }
      } else {
        return {
          ...prevState,
          [obj_name]: update_obj,
        }
      }
        
       });
      console.log('updatedd',contextState);
        // objContextState[obj_name] = update_obj;
        // setContextState({ ...contextState, objContextState });
        break;
    }
  };

  return (
    <ContextApiContext.Provider value={{ contextState, updateContextState }}>
      {props.children}
    </ContextApiContext.Provider>
  );
};

export default ContexApiProvider;
