import React, { useState, useEffect, useContext } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./../../styles/home.css";
import Carousel from "react-bootstrap/Carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStarHalfStroke } from "@fortawesome/free-solid-svg-icons";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Collapse from "react-bootstrap/Collapse";
import InputGroup from "react-bootstrap/InputGroup";
import { useNavigate } from "react-router-dom";

import { Alert } from "react-bootstrap";


// import Accordion from 'react-bootstrap/Accordion';
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";

export default function Footer() {
  

  return (
    <>
   

      <Container fluid className="bg_dblu">
        <Container>
          <Row className="">
            <Col xs={12} md={3}>
              <img
                className="logo_ft"
                src="/images/slicing/Help101-Web.png"
                alt="Content"
              />
            </Col>
            <Col xs={12} md={3}>
                  <ul className="list_ft">
                    <h5>Partnership</h5>
                    <li>Websites</li>
                    <li>Social Media</li>
                    <li>Branding</li>
                  </ul>
                </Col>
                <Col xs={12} md={3}>
                  <ul className="list_ft">
                    <h5>About</h5>
                    <li>Our Projects</li>
                    <li>Careers</li>
                  </ul>
                </Col>
                <Col xs={12} md={3}>
                  <ul className="list_ft">
                    <h5>Support</h5>
                    <li>Support Request</li>
                    <li>Contact</li>
                  </ul>
                </Col>
            <Col xs={12} md={9}>
              {/* <Row>
                <Col xs={12} md={12} className="ser_area">
                  <InputGroup className="mb-3 ft_mb3">
                    <Form.Control
                      placeholder="Your E-mail"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      className="ft_inp"
                    />
                    <InputGroup.Text
                      id="basic-addon2"
                      className="ft_inp_btn addon2ft"
                    >
                      Subscribe Now!
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
              </Row>
              <Row className="m-3">
                <Col xs={12} md={4}>
                  <ul className="list_ft">
                    <h5>Partnership</h5>
                    <li>Websites</li>
                    <li>Social Media</li>
                    <li>Branding</li>
                  </ul>
                </Col>
                <Col xs={12} md={4}>
                  <ul className="list_ft">
                    <h5>About</h5>
                    <li>Our Projects</li>
                    <li>Careers</li>
                  </ul>
                </Col>
                <Col xs={12} md={4}>
                  <ul className="list_ft">
                    <h5>Support</h5>
                    <li>Support Request</li>
                    <li>Contact</li>
                  </ul>
                </Col>
              </Row> */}
            </Col>
          </Row>
        </Container>
        <Row>
          <Col>
            <div className="socialicon">
              <a href="https://www.instagram.com/"><img src="/images/slicing/instagram-logo.png" /></a>
              <a href="  https://twitter.com/?lang=en"><img src="/images/slicing/3536424.png" /></a>
              <a href="https://www.google.com/gmail/about/"><img src="/images/slicing/124013.png" /></a>
              <a href="https://www.facebook.com/"><img src="/images/slicing/3536394.png" /></a>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <div className="ft-hr">
            <h4>Copyright © 2023-2028 Help101.ca. All Rights Reserved.</h4>
          </div>
        </Row>
      </Container>
    </>
  );
}
