import React, { useEffect, useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import { Constant } from "../common/Constants";
import { ContextApiContext } from "../context/ContextApi";
import { SendRequestContetType } from "../common/Common";
import Nav_bar_area from "./NavBar";
import Map from "./Components/Map";
import Pagination from "./Components/Pagination";
import Footer from "./Components/Footer";
import "./../styles/Services_payment.css";
import "./../styles/categorydetaillisting.css";

export default function CategoryDetailListing() {
  const [bookmark, setBookmark] = useState([]);
  const { updateContextState } = useContext(ContextApiContext);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("init search");
    getBookmarkedResults(); // Fetch bookmarks on mount
  }, []);

  const getBookmarkedResults = async () => {
    try {
      const getBookmarkedResultsUrl = `${Constant.get_bookmarks}`;
      const res = await SendRequestContetType(
        "GET",
        getBookmarkedResultsUrl,
        null, // No payload needed for GET request
        true
      );

      console.log("Bookmarked Results Response:", res);
      if (res.status) {
        setBookmark(res.response);
      } else {
        console.log("Error:", res);
        updateContextState(res.error?.message[0], "error_msg");
      }
    } catch (error) {
      console.error("Error fetching bookmarked results:", error);
    }
  };

  const handleShowMoreClick = (result) => {
    navigate("/community_people", { state: { result } });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div>
      <Nav_bar_area className="colooo" />
      <div className="dggg">
        <Row>
          <Col>
            <h1>Bookmarked Results</h1>
          </Col>
        </Row>
        <Row>
          {bookmark.map((result) => (
            <Col xs={12} md={6} key={result.id}>
              <div className="box">
                <Row>
                  <Col xs={12} md={9}>
                    <div onClick={() => handleShowMoreClick(result)} className="inner-box1">
                      <h2>{result.name}</h2>
                      <h6>{result.category_name}</h6>
                      <h6>{result?.distance?.toFixed(2) ?? ''} {result?.distance ? 'KM' : ''}</h6>
                      <h6>{result.address}</h6>
                      <h6>{result.taxonomy ?? ""}</h6>
                      <div className="show_area">
                        <Button variant="" className="bt show_btn">Show More</Button>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} md={3}>
                    <div className="inner-box2">
                      <img className="logo_ft" src="/images/slicing/Help101-Web.png" alt="Content" />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          ))}
        </Row>
        <Pagination
          // Add Pagination logic if needed
        />
        <Row>
          <Map />
        </Row>
      </div>
      <Footer />
    </div>
  );
}
